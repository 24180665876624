import { Component, Input } from '@angular/core';
import { environment } from '../../environments/environment';
import { ColorMaster } from '../models/colorMaster.model';
import { ColorMasterRepository } from '../models/colorMaster.repository';
import { EstimateRepository } from '../models/estimate.repository';
import { InitialCost } from '../models/InitialCost.model';
import { LogoMaster } from '../models/logoMaster.model';
import { LogoMasterRepository } from '../models/logoMaster.repository';

@Component({
  selector: "estimate-property",
  styleUrls: ['property.component.css'],
  templateUrl: "property.component.html"
})
export class PropertyComponent {
  public panelOpenState = false;
  @Input() readonly = false;
  constructor(
    private repo: EstimateRepository,
    private logoMasterRepository: LogoMasterRepository,
    private colorMasterRepository: ColorMasterRepository) {
    logoMasterRepository.getVisibleLogoMasters();
    colorMasterRepository.getVisibleColorMasters();
  }

  fileName(logoMaster: LogoMaster) {
    const fileName = logoMaster.logo.replace(/^.*[\\\/]/, '');
    const preIndex = fileName.indexOf("_") + 1;
    return fileName.substring(preIndex);
  }

  getImageSrc(logoMaster: LogoMaster) {
    return `${environment.baseServerUrl}/api/logomasters/image/${logoMaster.logo}`;
  }

  get initialCost(): InitialCost {
    return this.repo.currentInitialCost;
  }

  get logoMasters(): LogoMaster[] {
    return this.logoMasterRepository.visibleLogoMasters || [];
  }

  logoMaster(id: number): LogoMaster {
    return (this.logoMasterRepository.visibleLogoMasters || []).find(c => c.logoMasterId == id) as LogoMaster;
  }

  get colorMasters(): ColorMaster[] {
    return this.colorMasterRepository.visibleColorMasters || [];
  }

  colorMaster(id: number): ColorMaster {
    return (this.colorMasterRepository.visibleColorMasters || []).find(c => c.colorMasterId == id) as ColorMaster;
  }

  updateInitialCostProperty() {
    this.repo.updateInitialCost(this.repo.currentInitialCost);
  }
}
