import { Component } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LogoMasterRepository } from "../models/logoMaster.repository";
import { LogoMaster } from "../models/logoMaster.model";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LogoMasterModalComponent } from './logoMasterModal.component';
import { environment } from '../../environments/environment';
@Component({
  templateUrl: "logoMaster.component.html",
  styleUrls: ['logoMaster.component.css']
})
export class LogoMasterComponent {
  constructor(private repo: LogoMasterRepository, public dialog: MatDialog) {
    this.repo.getLogoMasters();
  }

  get logoMasters() {
    return this.repo.logoMasters;
  }

  getImageSrc(logoMaster: LogoMaster) {
    return `${environment.baseServerUrl}/api/logomasters/image/${logoMaster.logo}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    let allItems = this.repo.logoMasters
    const from = event.previousIndex;
    const to = event.currentIndex;
    const current = allItems[from];
    moveItemInArray(allItems, from, to);

    // Computing a new orderValue for the object that was moved
    let lowerBound = 0.0;
    // Is there an object before it in the array?
    if (to > 0) {
      lowerBound = allItems[to - 1].order;
    } else {
      lowerBound = allItems[1].order - 2.0;
    }
    let upperBound = 0.0;
    // Is there an object after it in the array?
    if (to < allItems.length - 1) {
      upperBound = allItems[to + 1].order;
    } else {
      upperBound = allItems[to - 1].order + 2.0;
    }
    const newOrderValue = (lowerBound + upperBound) / 2.0;
  
    current.order = newOrderValue;

    const formData = new FormData();
    formData.set("logoMasterData", JSON.stringify(current));
    this.repo.moveLogoMaster(current.logoMasterId, formData)
      .subscribe(() => this.repo.getLogoMasters());
  }

  add() {
    const ref: MatDialogRef<LogoMasterModalComponent> = this.dialog.
      open(LogoMasterModalComponent, {
        data: new LogoMaster(0, '', true, 0),
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  edit(logoMaster: LogoMaster) {
    const ref: MatDialogRef<LogoMasterModalComponent> = this.dialog.
      open(LogoMasterModalComponent, {
        data: logoMaster,
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  remove(logoMaster: LogoMaster) {
    const details = this.repo.logoMasters;
    const index = details.indexOf(logoMaster);
    if (index > -1) {
      this.repo.deleteLogoMaster(logoMaster).subscribe(() => this.repo.getLogoMasters());
    }
  }
}
