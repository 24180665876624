<div *ngIf="isLoading | async; else loaded">
  <mat-progress-spinner class="spinner" [diameter]="diameter" [color]="color" [mode]="mode" [value]="value"></mat-progress-spinner>
</div>
<ng-template #loaded>
  <mat-accordion class="m-3">
    <mat-expansion-panel [expanded]="panelOpenState">
      <mat-expansion-panel-header #panelH (click)="panelH._toggle();panelOpenState=!panelOpenState">
        <mat-panel-title>
          <i class="material-icons app-toolbar-menu m-2" (click)="panelH._toggle();panelOpenState=!panelOpenState">
            追加項目
          </i>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table class="table table-responsive table-borderless">
        <tr>
          <td>
            概算の毎月の費用
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <input readonly [value]="initialTotalCost.toNumber() | number:'1.0-0'" />&nbsp;円（税込）
          </td>
        </tr>
      </table>
      <table cdkDropList *ngIf="additionalItems?.length > 0; else nodata" class="table table-bordered table-responsive drag-and-drop-list" (cdkDropListDropped)="drop($event)">
        <thead>
          <tr class="m-1 p-2 p-1t">
            <td class="col-md nowrap">
              項目名
            </td>
            <td class="col-md nowrap">
              金額
            </td>
            <td class="col-md nowrap col-min-width">
              備考
            </td>
            <td class="col-md col-min-width">
            </td>
          <tr>
        </thead>
        <tbody>
          <ng-template ngFor let-additionalItem [ngForOf]="additionalItems">
            <tr cdkDrag class="m-1 p-2 p-1t drag-and-drop-box" [cdkDragDisabled]="readonly">
              <td class="col-md nowrap">{{ additionalItem.name }}</td>
              <td class="col-md nowrap text-right">
                {{ additionalItem.cost | number:'1.0-0'}}円
              </td>
              <td class="col-md nowrap col-min-width text-right">{{ additionalItem.remarks }}</td>
              <td *ngIf="!readonly" class="col-md col-min-width">
                <button class="rate-button m-1" (click)="edit(additionalItem)">
                  変更
                </button>
                <button *ngIf="additionalItem.canDelete" class="rate-button m-1" (click)="remove(additionalItem)">
                  削除
                </button>
              </td>
            <tr>
          </ng-template>
        </tbody>
      </table>

      <ng-container *ngIf="!readonly">
        <div class="row justify-content-center m-2">
          <h1 (click)="add(initialCost.initialCostId)"><i class="fas fa-plus-circle"></i></h1>
        </div>
        <div class="row justify-content-center">
          項目追加
        </div>
        <div>&nbsp;</div>
      </ng-container>
      <ng-template #nodata>
        <h4 class="m-2">ありません</h4>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
