import { Component } from "@angular/core";
import { FixedItemMasterRepository } from "../models/fixedItemMaster.repository";
import { InitialCostMaster } from "../models/InitialCostMaster.model";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';


@Component({
  templateUrl: "initialCostMasters.component.html"
})
export class IntialCostMasterComponent {
  constructor(private repo: FixedItemMasterRepository, public dialog: MatDialog) { }

  tableMode: boolean = true;

  selectInitialCostMaster(id: string) {
    this.repo.getInitialCostMaster(id);
  }

  saveInitialCostMaster() {
    if (this.repo.initialCostMaster.initialCostMasterId == null) {
      this.repo.insertInitialCostMaster(this.repo.initialCostMaster);
    } else {
      this.repo.replaceInitialCostMaster(this.repo.initialCostMaster)
    }
    this.clearInitialCostMaster();
    this.tableMode = true;
  }

  deleteInitialCostMaster(initialCostMaster: InitialCostMaster) {
    this.repo.deleteInitialCostMaster(initialCostMaster);
  }

  clearInitialCostMaster() {
    this.repo.initialCostMaster = new InitialCostMaster();
    this.tableMode = true;
  }

  get initialCostMaster(): InitialCostMaster {
    return this.repo.initialCostMaster;
  }
  get initialCostMasters(): InitialCostMaster[] {
    return this.repo.initialCostMasters;
  }
}
