import { FixedItemOption } from "./fixedItemOption.model";
import Decimal from "decimal.js";

export class FixedItem {
  constructor(
    public fixedItemId?: number,
    public customerId?: string,
    public name?: string,
    public status?: FixedItemOption,
    public unit?: string,
    public cost?: Decimal,
    public isPayByDays?: boolean,
    public remarks?: string,
    public initialCostId?: string,
    public guid?: string,
    public order?: number,
    public canDelete?: boolean,
    public options?: FixedItemOption[],
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string,
    public createdDate?: Date,
    public updatedDate?: Date) { }
}
