import { FixedItemOptionStatus } from "./fixedItemOptionStatus.model";

export class FixedItemOption {
  constructor(
    public fixedItemOptionId?: number,
    public fixedItemId?: number,
    public customerId?: string,
    public fixedItemOptionStatus?: FixedItemOptionStatus,
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string,
    public createdDate?: Date,
    public updatedDate?: Date) { }
}
