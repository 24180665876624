import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { EstimateRepository } from "./estimate.repository";
import { FixedItemMasterRepository } from "./fixedItemMaster.repository";
import { AdditionalItemMasterRepository } from './additionalItemMaster.repository';
import { ColorMasterRepository } from './colorMaster.repository';
import { LogoMasterRepository } from './logoMaster.repository';
import { SentenceMasterRepository } from './sentenceMaster.repository';
import { AccountRepository } from "./account.repository";
import { DocumentExportRepository } from "./documentExport.repositoery";
import { CognitoRepository } from "./cognito.repository";

@NgModule({
    imports: [HttpClientModule],
    providers: [
        EstimateRepository,
        FixedItemMasterRepository,
        AdditionalItemMasterRepository,
        ColorMasterRepository,
        LogoMasterRepository,
        SentenceMasterRepository,
        AccountRepository,
        DocumentExportRepository,
        CognitoRepository
    ]
})
export class ModelModule { }
