import { FixedItemMaster } from './fixedItemMaster.model';

export class InitialCostMaster {
  constructor(
    public initialCostMasterId?: string,
    public userId?: string,
    public name?: string,
    public initialCostDetailMasters: FixedItemMaster[] = [],
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string,
    public createdDate?: Date,
    public updatedDate?: Date) { }
}
