import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AdminCreateUser } from '../models/adminCreateUser.model';
import { AdminUpdateUserAttributes } from '../models/adminUpdateUserAttributes.model';
import { CognitoRepository } from '../models/cognito.repository';
import { UserType } from '../models/userType.model';

@Component({
    templateUrl: 'cognitoUserModal.component.html',
})
export class CognitoUserModalComponent {
    adminCreateUser: AdminCreateUser = null;
    adminUpdateUserAttributes: AdminUpdateUserAttributes = null;
    userTypes: UserType[];

    constructor(
        private repo: CognitoRepository,
        public dialogRef: MatDialogRef<CognitoUserModalComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

        if ((data[0].userName ?? "").length == 0) {
            this.adminCreateUser = new AdminCreateUser();
        } else {
            this.adminUpdateUserAttributes = new AdminUpdateUserAttributes(
                data[0].userName,
                data[0].accountName,
                data[0].email,
                data[0].insuranceCompanyId,
                data[0].linkageType,
                data[0].roleType);
        }
        this.userTypes = data[1];
    }

    public onOkClick(): void {
        let matched = this.userTypes.find(p => this.adminUpdateUserAttributes != null && p.userName == this.adminUpdateUserAttributes.userName);
        if (matched) {
            matched.userName = this.adminUpdateUserAttributes.userName;
            matched.accountName = this.adminUpdateUserAttributes.accountName;
            matched.email = this.adminUpdateUserAttributes.email;
            matched.insuranceCompanyId = this.adminUpdateUserAttributes.insuranceCompanyId;
            matched.linkageType = this.adminUpdateUserAttributes.linkageType;
            matched.roleType = this.adminUpdateUserAttributes.roleType;
            this.repo.replaceUser(matched)
                .subscribe(() => { this.dialogRef.close(true); });
        } else {
            this.repo.insertUser(this.adminCreateUser)
                .subscribe(() => { this.dialogRef.close(true); });
        }
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }
}
