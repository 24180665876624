import { NgModule } from "@angular/core";
import { ErrorDialogComponent } from "./errors/error-dialog/error-dialog.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ErrorDialogService } from "./errors/error-dialog.service";
import { MaterialModule } from "../material.module";
import { SpinnerComponent } from "./spinner/spinner-ui/spinner.component";
import { SpinnerService } from "./spinner/spinner.service";

const sharedComponents = [ErrorDialogComponent, SpinnerComponent];

@NgModule({
    declarations: [...sharedComponents],
    imports: [CommonModule, RouterModule, MaterialModule],
    exports: [...sharedComponents],
    providers: [ErrorDialogService, SpinnerService]
})
export class SharedModule { }
