import { ColorMaster } from "./colorMaster.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Color } from '@angular-material-components/color-picker';
import { environment } from "../../environments/environment";

const baseUrl = environment.baseServerUrl;
const colorMastersUrl = baseUrl + "/api/colormasters";

@Injectable()
export class ColorMasterRepository {
  visibleColorMasters: ColorMaster[];
  colorMasters: ColorMaster[];

  constructor(private http: HttpClient) { }

  getVisibleColorMasters() {
    this.http.get<ColorMaster[]>(`${colorMastersUrl}?all=false`)
      .subscribe(details => {
        this.visibleColorMasters = details;
        if (this.visibleColorMasters) {
          this.visibleColorMasters.forEach(d => d.color = new Color(d.r, d.g, d.b, d.a));
        }
      });
  }

  getColorMasters() {
    this.http.get<ColorMaster[]>(`${colorMastersUrl}`)
      .subscribe(details => {
        this.colorMasters = details;
        if (this.colorMasters) {
          this.colorMasters.forEach(d => d.color = new Color(d.r, d.g, d.b, d.a));
        }
      });
  }

  getColorMaster(id: number) {
    this.http.get<ColorMaster[]>(`${colorMastersUrl}/${id}`)
      .subscribe(details => {
        this.colorMasters = details;
        if (this.colorMasters) {
          this.colorMasters.forEach(d => d.color = new Color(d.r, d.g, d.b, d.a));
        }
      });
  }

  insertColorMaster(colorMaster: ColorMaster): Observable<any> {
    return this.http.post(`${colorMastersUrl}`, colorMaster);
  }

  replaceColorMaster(colorMaster: ColorMaster): Observable<any> {
    return this.http.put(`${colorMastersUrl}/${colorMaster.colorMasterId}`, colorMaster);
  }

  deleteColorMaster(colorMaster: ColorMaster): Observable<any> {
    return this.http.delete(`${colorMastersUrl}/${colorMaster.colorMasterId}`);
  }
}
