import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EstimateRepository } from '../../models/estimate.repository';
import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { InitilaCostModalComponent } from '../initialCostModal.component';

@Component({
  templateUrl: './initialCostS3Modal.component.html',
  styleUrls: ['./initialCostS3Modal.component.css']
})
export class InitilaCostS3ModalComponent implements OnInit {
  id: string;

  constructor(
    private matSnackBar: MatSnackBar,
    private repo: EstimateRepository,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InitilaCostS3ModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: string) {
    this.id = data;
  }

  get isJsonFileExist() {
    return this.repo.isJsonFileExist;
  }

  get name() {
    return this.repo.currentInitialCost?.name;
  }

  get objectUrl() {
    return this.repo.objectUrl;
  }

  ngOnInit() {
    const id = this.id
    if (id) {
      this.repo.getCurrentInitialCost(
        id,
        this.repo.customerId, true);
    }
  }

  reopenDialog() {
    this.dialogRef.close();

    const ref: MatDialogRef<InitilaCostModalComponent> = this.dialog.
      open(InitilaCostModalComponent, {
        data: { id: this.repo.currentInitialCost.initialCostId, awss3: true },
        width: "95%",
        maxHeight: "60%",
        position: { top: '5%' },
        hasBackdrop: true,
        panelClass: ['animate__animated', 'animate__slideInLeft']
      });
  }
}
