import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

/**
 * スピナーの表示を制御するサービス
 *
 * @export
 * @class SpinnerService
 */
@Injectable()
export class SpinnerService {
  public isLoading = new Subject<boolean>();
  public isAdditionalItemsLoading = new BehaviorSubject<boolean>(false);
  public isFixedItemsLoading = new BehaviorSubject<boolean>(false);
  public isSentenceItemsLoading = new BehaviorSubject<boolean>(false);
  public canEdit: boolean;

  public invalidate() {
    combineLatest([this.isLoading, this.isAdditionalItemsLoading, this.isFixedItemsLoading, this.isSentenceItemsLoading])
      .subscribe(results => {
        this.canEdit = results[0].valueOf() == false && results[1].valueOf() == false && results[2].valueOf() == false && results[3].valueOf() == false;
      });
  }

  constructor() { }

  /**
   * スピナーの表示
   *
   * @memberof SpinnerService
   */
  public show(url: string): void {
    if (!url) {
      return;
    }
    if (url.indexOf("api/additionalitems") > -1) {
      this.isAdditionalItemsLoading.next(true);
    } else if (url.indexOf("api/fixeditems") > -1) {
      this.isFixedItemsLoading.next(true);
    } else if (url.indexOf("api/sentenceitems") > -1) {
      this.isSentenceItemsLoading.next(true);
    } else {
      this.isLoading.next(true);
    }
    this.invalidate();
  }

  /**
   * スピナーの非表示
   *
   * @memberof SpinnerService
   */
  public hide(url: string): void {
    if (!url) {
      return;
    }
    if (url.indexOf("api/additionalitems") > -1) {
      this.isAdditionalItemsLoading.next(false);
    } else if (url.indexOf("api/fixeditems") > -1) {
      this.isFixedItemsLoading.next(false);
    } else if (url.indexOf("api/sentenceitems") > -1) {
      this.isSentenceItemsLoading.next(false);
    } else {
      this.isLoading.next(false);
    }
    this.invalidate();
  }
}
