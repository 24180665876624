import { FixedItemMasterOption } from "./fixedItemMasterOption.model";
import Decimal from "decimal.js";

export class FixedItemMaster {
  constructor(
    public fixedItemMasterId?: number,
    public name?: string,
    public cost?: Decimal,
    public remarks?: string,
    public isPayByDays?: boolean,
    public visible?: boolean,
    public order?: number,
    public createdDate?: Date,
    public updatedDate?: Date,
    public options?: FixedItemMasterOption[],
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string) { }
}
