import { LogoMaster } from "./logoMaster.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

const baseUrl = environment.baseServerUrl;
const logoMastersUrl = baseUrl + "/api/logomasters";

@Injectable()
export class LogoMasterRepository {
  logoMasters: LogoMaster[];
  visibleLogoMasters: LogoMaster[];

  constructor(private http: HttpClient) { }

  getVisibleLogoMasters() {
    this.http.get<LogoMaster[]>(`${logoMastersUrl}?all=false`)
      .subscribe(details => {
        this.visibleLogoMasters = details;
      });
  }

  getLogoMasters() {
    this.http.get<LogoMaster[]>(`${logoMastersUrl}`)
      .subscribe(details => {
        this.logoMasters = details;
      });
  }

  getLogoMaster(id: number) {
    this.http.get<LogoMaster[]>(`${logoMastersUrl}/${id}`)
      .subscribe(details => {
        this.logoMasters = details;
      });
  }

  insertLogoMaster(formData: FormData): Observable<any> {
    return this.http.post(`${logoMastersUrl}`, formData);
  }

  replaceLogoMaster(id: number, formData: FormData): Observable<any> {
    return this.http.put(`${logoMastersUrl}/${id}`, formData);
  }

  moveLogoMaster(id: number, formData: FormData): Observable<any> {
    return this.http.put(`${logoMastersUrl}/move/${id}`, formData);
  }

  deleteLogoMaster(logoMaster: LogoMaster): Observable<any> {
    return this.http.delete(`${logoMastersUrl}/${logoMaster.logoMasterId}`);
  }
}
