<table class="table table-responsive table-borderless">
  <tr>
    <td>契約予定日</td>
    <td></td>
    <td></td>
    <td></td>
    <td>
      <ng-container *ngIf="!readonly; else no">
        <input #ref
               [matDatepicker]="picker"
               [ngModel]="scheduledContractDate"
               (dateChange)="onChange(ref.value)"
               placeholder="日付を選択してください" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </ng-container>
    </td>
  </tr>
  <tr>
    <td>
      概算初期費用
    </td>
    <td></td>
    <td></td>
    <td></td>
    <td>
      <input readonly [value]="initialTotalCost.toNumber() | number:'1.0-0'" />&nbsp;円（税込）
    </td>
  </tr>
</table>

<ng-template #no>
  {{scheduledContractDate | date}}
</ng-template>
