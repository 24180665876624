<amplify-authenticator usernameAlias="email" [hideSignUp]="true">
    <app-spinner></app-spinner>
    <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
        <div>
            ようこそ{{loginName(user)}}さん
            <button class="btn btn-sm" (click)="signOut()">
                ログアウト
            </button>
        </div>
        <div class="App">
            <router-outlet></router-outlet>
        </div>
    </ng-template>
</amplify-authenticator>
<div class="text-center pt-2">
    {{accessToken}}
    <button class="btn btn-warning btn-lg btn-block" (click)="getToken()">
        Get Token
    </button>
</div>