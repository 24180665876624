import { Component, Output, EventEmitter, Input } from '@angular/core';
import { EstimateRepository } from "../models/estimate.repository";
import { InitialCost } from "../models/InitialCost.model";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PropertyComponent } from './property.component';
import { Router } from '@angular/router';
import { SpinnerService } from '../shared/spinner/spinner.service';

@Component({
  selector: "estimate-property-title",
  templateUrl: "propertyTitle.component.html",
  styleUrls: ['propertyTitle.component.css']
})
export class PropertyTitleComponent {
  constructor(private repo: EstimateRepository, private matSnackBar: MatSnackBar, public dialogRef: MatDialogRef<PropertyComponent>, private router: Router, private spinnerService: SpinnerService) { }
  @Output() toggleClicked = new EventEmitter();
  @Output() saveClicked = new EventEmitter();
  @Output() getPdfClicked = new EventEmitter<string>();
  @Output() getExcelClicked = new EventEmitter<string>();
  @Input() readonly = false;

  get canEdit() {
    return this.spinnerService.canEdit;
  }

  get initialCost(): InitialCost {
    return this.repo.currentInitialCost;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public navigate(): void {
    this.dialogRef.close();
    this.router.navigate(['/master', 'additionalitemmaster']);
  }

  reset() {
    this.repo.resetCurrentInitialCost();
  }

  onToggleClicked() {
    this.toggleClicked.emit();
  }

  onSaveClicked() {
    if (!this.initialCost.staffName || !this.initialCost.customerName) {
      return;
    }
    this.repo.createInitialCostHistory()
      .subscribe(() => {
        this.matSnackBar.open("登録しました。", null, {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        this.saveClicked.emit();
      },
        (err) => {
          this.matSnackBar.open(err, null, {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          console.log(err)
        });
  }

  public onGetPdfClicked(name: string) {
    this.getPdfClicked.emit(name);
  }

  public onGetExcelClicked(name: string) {
    this.getExcelClicked.emit(name);
  }
}
