<h1 mat-dialog-title class="red-color text-white p-2">
  項目{{additionalItem.additionalItemId == 0 ? "追加" : "編集" }}
</h1>
<div mat-dialog-content>
  <div *ngIf="detailsForm.invalid" class="validation-summary">
    <ul>
      <li *ngIf="name.invalid" class="text-danger">
        内訳費用項目を入力してください
      </li>
      <li *ngIf="cost.invalid" class="text-danger">
        金額を入力してください
      </li>
    </ul>
  </div>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>内訳費用項目</label>
      <input #name="ngModel" name="name" class="form-control"
             [(ngModel)]="additionalItem.name" required />
      <div *ngIf="name.invalid" class="text-danger">
        内訳費用項目を入力してください
      </div>
    </div>
    <div class="form-group">
      <label>金額</label>
      <input #cost="ngModel" name="cost" type="number" class="form-control"
             [(ngModel)]="additionalItem.cost" required />
      <div *ngIf="cost.invalid" class="text-danger">
        金額を入力してください
      </div>
    </div>
    <div class="form-group">
      <label>備考</label>
      <input #address="ngModel" name="remarks" class="form-control"
             [(ngModel)]="additionalItem.remarks" />
    </div>
    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
