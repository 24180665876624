import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErrorHandlerModule } from "./errors/error-handler.module";
import { SpinnerHandlerModule } from "./spinner/spinner-handler.module";
import { HttpXsrfHandlerModule } from "./xsrf/httpxsrf-handler.module";
import { AnonymousRequestModule } from "./anonymous-request/anonymous-request.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, ErrorHandlerModule, SpinnerHandlerModule, HttpXsrfHandlerModule, AnonymousRequestModule]
})
export class CoreModule { }
