import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModelModule } from "./models/model.module";
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EstimateModule } from "./estimate/estimate.module";
import { MasterModule } from "./master/master.module";
import localeJa from '@angular/common/locales/ja';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ExternalAuthInterceptor } from './auth/externalAuthuthInterceptor.service';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { MaterialModule } from "./material.module";
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

registerLocaleData(localeJa);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModelModule,
    FormsModule,
    MatSidenavModule,
    EstimateModule,
    MasterModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    AmplifyAuthenticatorModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    { provide: HTTP_INTERCEPTORS, useClass: ExternalAuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
