<table *ngIf="tableMode; else create" class="table table-sm table-striped">
  <tbody>
    <tr>
      <th>ID</th>
      <th>名前</th>
      <th></th>
    </tr>
    <tr *ngFor="let p of initialCostMasters">
      <ng-template [ngIf]="initialCostMaster?.initialCostMasterId != p.initialCostMasterId"
                   [ngIfElse]="edit">
        <td>{{p.initialCostMasterId}}</td>
        <td>{{p.name}}</td>
        <td>
          <button class="btn btn-sm btn-primary"
                  (click)="selectInitialCostMaster(p.initialCostMasterId)">
            編集
          </button> 
          <button class="btn btn-sm btn-danger ml-1"
                  (click)="deleteInitialCostMaster(p)">
            削除
          </button>
        </td>
      </ng-template>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="6" class="text-center">
        <button class="btn btn-primary"
                (click)="clearInitialCostMaster(); tableMode = false">
          新規
        </button>
      </td>
    </tr>
  </tfoot>
</table>

<ng-template #edit>
  <td colspan="6" class="bg-light border p-3">
    <initial-cost-master-editor></initial-cost-master-editor>
    <button class="btn btn-primary" (click)="saveInitialCostMaster()">
      保存
    </button>
    <button class="btn btn-info m-1" (click)="clearInitialCostMaster()">
      キャンセル
    </button>
  </td>
</ng-template>

<ng-template #create>
  <div class="m-2">
    <initial-cost-master-editor></initial-cost-master-editor>
    <button class="btn btn-primary" (click)="saveInitialCostMaster()">
      保存
    </button>
    <button class="btn btn-info m-1" (click)="clearInitialCostMaster()">
      キャンセル
    </button>
  </div>
</ng-template>

