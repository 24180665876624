<h1 mat-dialog-title>エラーが発生しました。</h1>
<div mat-dialog-content>
  <p *ngIf="data.status">
    <b>ステータスコード: {{data.status}}</b>
  </p>
  <p class="error-message">
    {{data?.message}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="primary">
    閉じる
  </button>
</div>
