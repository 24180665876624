import { Component } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SentenceMasterRepository } from "../models/sentenceMaster.repository";
import { SentenceMaster } from "../models/sentenceMaster.model";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SentenceMasterModalComponent } from './sentenceMasterModal.component';
import { SentencePosition } from '../models/sentencePosition.model';

@Component({
  templateUrl: "sentenceMaster.component.html",
  styleUrls: ['sentenceMaster.component.css']
})
export class SentenceMasterComponent {
  constructor(private repo: SentenceMasterRepository, public dialog: MatDialog) {
    this.repo.getSentenceMasters();
  }

  get sentenceMasters() {
    return this.repo.sentenceMasters;
  }

  drop(event: CdkDragDrop<string[]>) {
    let allItems = this.repo.sentenceMasters
    const from = event.previousIndex;
    const to = event.currentIndex;
    const current = allItems[from];
    moveItemInArray(allItems, from, to);

    // Computing a new orderValue for the object that was moved
    let lowerBound = 0.0;
    // Is there an object before it in the array?
    if (to > 0) {
      lowerBound = allItems[to - 1].order;
    } else {
      lowerBound = allItems[1].order - 2.0;
    }
    let upperBound = 0.0;
    // Is there an object after it in the array?
    if (to < allItems.length - 1) {
      upperBound = allItems[to + 1].order;
    } else {
      upperBound = allItems[to - 1].order + 2.0;
    }
    const newOrderValue = (lowerBound + upperBound) / 2.0;

    current.order = newOrderValue;
    this.repo.replaceSentenceMaster(current)
      .subscribe(() => this.repo.getSentenceMasters());
  }

  add() {
    const ref: MatDialogRef<SentenceMasterModalComponent> = this.dialog.
      open(SentenceMasterModalComponent, {
        data: new SentenceMaster(0, "", SentencePosition.sentence1, true, 0),
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  edit(sentenceMaster: SentenceMaster) {
    const ref: MatDialogRef<SentenceMasterModalComponent> = this.dialog.
      open(SentenceMasterModalComponent, {
        data: sentenceMaster,
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  remove(sentenceMaster: SentenceMaster) {
    const details = this.repo.sentenceMasters;
    const index = details.indexOf(sentenceMaster);
    if (index > -1) {
      this.repo.deleteSentenceMaster(sentenceMaster).subscribe(() => this.repo.getSentenceMasters());
    }
  }

  positionName(sentenceMaster: SentenceMaster) {
    switch (sentenceMaster.position) {
      case SentencePosition.sentence1:
        return "挨拶①";
      case SentencePosition.sentence2:
        return "挨拶②";
      default:
        return "";
    }
  }
}
