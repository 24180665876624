import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ColorMasterRepository } from "../models/colorMaster.repository";
import { ColorMaster } from '../models/colorMaster.model';

@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'colorMasterModal.component.html',
})
export class ColorMasterModalComponent {
  colorMaster: ColorMaster;

  constructor(
    private repo: ColorMasterRepository,
    public dialogRef: MatDialogRef<ColorMasterModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: ColorMaster) {
    this.colorMaster = new ColorMaster(
      data.colorMasterId,
      data.color,
      data.visible,
      data.order,
      data.createdDate,
      data.updatedDate);
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const details = this.repo.colorMasters;
    let matched: ColorMaster = details.find(p => p.colorMasterId == this.colorMaster.colorMasterId);
    if (matched) {
      matched.color = this.colorMaster.color;
      matched.r = this.colorMaster.color.r;
      matched.g = this.colorMaster.color.g;
      matched.b = this.colorMaster.color.b;
      matched.a = this.colorMaster.color.a;
      matched.visible = this.colorMaster.visible;
      matched.order = this.colorMaster.order || 0;
      matched.createdDate = this.colorMaster.createdDate;
      this.repo.replaceColorMaster(matched)
        .subscribe(() => this.repo.getColorMasters());
    } else {
      this.colorMaster.r = this.colorMaster.color.r;
      this.colorMaster.g = this.colorMaster.color.g;
      this.colorMaster.b = this.colorMaster.color.b;
      this.colorMaster.a = this.colorMaster.color.a;
      this.repo.insertColorMaster(this.colorMaster)
        .subscribe(() => this.repo.getColorMasters());
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
