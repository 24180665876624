import { Component, Input } from "@angular/core";
import { EstimateRepository } from "../models/estimate.repository";
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FixedItem } from '../models/fixedItem.model';
import { FixedItemsModalComponent } from '../estimate/fixedItemsModal.component';
import { InitialCost } from '../models/InitialCost.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FixedItemOptionStatus } from "../models/fixedItemOptionStatus.model";
import { FixedItemOption } from "../models/fixedItemOption.model";
import { Subject } from "rxjs";
import { SpinnerService } from "../shared/spinner/spinner.service";
import Decimal from "decimal.js";

@Component({
  selector: "estimate-fixed-items",
  templateUrl: "fixedItems.component.html",
  styleUrls: ['fixedItems.component.css']
})
export class FixedItemsComponent {
  @Input() readonly = false;
  public panelOpenState = false;
  public color = 'primary';
  public mode = 'indeterminate';
  public value = 25;
  public diameter = 50;
  public isLoading: Subject<boolean> = this.spinnerService.isFixedItemsLoading;
  constructor(private repo: EstimateRepository,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private spinnerService: SpinnerService) { }

  get initialCost(): InitialCost {
    return this.repo.currentInitialCost;
  }

  get fixedItems(): FixedItem[] {
    if (this.repo.currentInitialCost) {
      return this.repo.currentInitialCost.fixedItems;
      //.sort((x, y) => (x.isPayByDays === y.isPayByDays) ? 0 : x.isPayByDays ? 1 : -1);
    }
    return null;
  }

  optionName(value: number) {
    switch (value) {
      case 0:
        return "なし";
      case 1:
        return "月数";
      case 2:
        return "金額";
      case 3:
        return "割合";
      case 4:
        return "家賃に含む";
    }
  }

  getday(fixedItem: FixedItem) {
    if (fixedItem.isPayByDays) {
      if (this.repo.currentInitialCost) {

        const lastDay = new Date(
          this.repo.currentInitialCost.scheduledContractDate.getFullYear(),
          this.repo.currentInitialCost.scheduledContractDate.getMonth() + 1, 0).getDate();
        const day = lastDay - this.repo.currentInitialCost.scheduledContractDate.getDate()
        return `日割り（${day}日分）`;
      }
    }
    return "";
  }

  paymentByDays(fixedItem: FixedItem) {
    return this.repo.paymentByDays(fixedItem);
  }

  newFixedItemOptions(fixedItenId: number, customerId: string) {
    const options = [];
    options.push(
      new FixedItemOption(0, fixedItenId, customerId, FixedItemOptionStatus.なし),
      new FixedItemOption(0, fixedItenId, customerId, FixedItemOptionStatus.月数),
      new FixedItemOption(0, fixedItenId, customerId, FixedItemOptionStatus.金額),
      new FixedItemOption(0, fixedItenId, customerId, FixedItemOptionStatus.割合));
    return options;
  }

  add(initialCostId: string) {
    const options = this.newFixedItemOptions(0, this.repo.customerId);
    const ref: MatDialogRef<FixedItemsModalComponent> = this.dialog.
      open(FixedItemsModalComponent, {
        data: new FixedItem(0, this.repo.customerId, "", null, "", null, true, "", initialCostId, this.repo.guid, null, true, options),
        width: "600px",
        height: "600px",
        hasBackdrop: true
      });
  }

  edit(fixedItem: FixedItem) {
    const ref: MatDialogRef<FixedItemsModalComponent> = this.dialog.
      open(FixedItemsModalComponent, {
        data: fixedItem,
        width: "600px",
        height: "600px",
        hasBackdrop: true
      });
  }

  remove(fixedItem: FixedItem) {
    const details = this.repo.currentInitialCost.fixedItems;
    const index = details.indexOf(fixedItem);
    if (index > -1) {
      details.splice(index, 1);
      this.repo.removeFixedItem(fixedItem);
    }
  }

  updateFixedItemStatus(fixedItem: FixedItem) {
    const numberPattern = /\d+/g;
    this.repo.getTemporaryFixedItem(fixedItem.fixedItemId).subscribe(f => {
      if (f) {
        const numberPart = Number(((fixedItem.unit || '').match(numberPattern) || []).join(''));
        switch (fixedItem.status) {
          // case "3":
          //   fixedItem.unit = numberPart + "%";
          //   fixedItem.remarks = "1か月分の" + numberPart + "%";
          //   fixedItem.cost = f.cost.times(new Decimal(numberPart)).div(new Decimal(100));
          //   break;
          // case "1":
          //   fixedItem.unit = numberPart.toString();
          //   fixedItem.remarks = numberPart + "か月分";
          //   fixedItem.cost = f.cost.times(new Decimal(numberPart));
          //   break;
          default:
            fixedItem.unit = "";
            fixedItem.remarks = "";
            fixedItem.cost = f.cost;
            break;
        }
      }
    });
    this.repo.updateFixedItem(fixedItem);
  }

  drop(event: CdkDragDrop<string[]>) {
    let allItems = this.fixedItems
    const from = event.previousIndex;
    const to = event.currentIndex;
    const current = allItems[from];
    moveItemInArray(allItems, from, to);

    // Computing a new orderValue for the object that was moved
    let lowerBound = 0.0;
    // Is there an object before it in the array?
    if (to > 0) {
      lowerBound = allItems[to - 1].order;
    } else {
      lowerBound = allItems[1].order - 2.0;
    }
    let upperBound = 0.0;
    // Is there an object after it in the array?
    if (to < allItems.length - 1) {
      upperBound = allItems[to + 1].order;
    } else {
      upperBound = allItems[to - 1].order + 2.0;
    }
    const newOrderValue = (lowerBound + upperBound) / 2.0;

    current.order = newOrderValue;
    this.repo.updateFixedItem(current);
  }
}
