import Decimal from "decimal.js";

export class AdditionalItemMaster {
  constructor(
    public additionalItemMasterId?: number,
    public name?: string,
    public cost?: Decimal,
    public remarks?: string,
    public visible?: boolean,
    public order?: number,
    public createdDate?: Date,
    public updatedDate?: Date,
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string
  ) { }
}
