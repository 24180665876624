<div class="navbar red-color mb-1">
    <a class="navbar-brand text-white">初期費用概算</a>
</div>
<div class="container-fluid">
    <div class="row">
        <img src="../../assets/images/Angular-15.png" class="img-responsive mg-thumbnail center-block d-block mx-auto"
            width="400" />
    </div>
    <div class="row">
        <div class="col">
            <div class="card m-2">
                <div class="card-body">
                    <h4 class="m-2">ECS＋Fargateから取得（バックエンドアクセス有り⭐️{{retry_count}}）</h4>
                    <div *ngFor="let initialCost of initialCosts" class="card m-1 p-1 bg-light">
                        <button class="btn btn-sm text-left" (click)="openDialog(initialCost.initialCostId)">
                            <h4>{{ initialCost.name }}</h4>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card m-2">
                <div class="card-body">
                    <h4 class="m-2">aws S3のJsonから取得（バックエンドアクセス無し）</h4>
                    <div *ngFor="let initialCost of initialCosts" class="card m-1 p-1 bg-light">
                        <button class="btn btn-sm text-left" (click)="openDialog(initialCost.initialCostId, true)">
                            <h4>{{ initialCost.name }}</h4>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>