import { Component } from "@angular/core";
import { FixedItemMasterRepository } from "../models/fixedItemMaster.repository";
import { InitialCostMaster } from "../models/InitialCostMaster.model";

@Component({
  selector: "initial-cost-master-editor",
  templateUrl: "initialCostMasterEditor.component.html"
})
export class InitialCostMasterEditorComponent {
  constructor(private repo: FixedItemMasterRepository) { }

  get initialCostMaster(): InitialCostMaster {
    return this.repo.initialCostMaster;
  }
}
