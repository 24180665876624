import { SentencePosition } from "./sentencePosition.model";

export class SentenceItem {
  constructor(
    public sentenceItemId?: number,
    public customerId?: string,
    public position?: SentencePosition,
    public sentence?: string,
    public isSelected?: boolean,
    public order?: number,
    public initialCostId?: string,
    public guid?: string,
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string,
    public createdDate?: Date,
    public updatedDate?: Date) { }
}
