<div class="card">
    <div class="card-header">
        フィルター（Cognitoの仕様で１つだけ適用されます）
    </div>
    <div class="form-group m-2">
        <label for="f1">メールアドレス</label>
        <input class="form-control" id="f1" [(ngModel)]="email" (change)="refresh()">
    </div>
    <div class="form-group m-2">
        <label for="f2">accountId</label>
        <input class="form-control" id="f2" [(ngModel)]="accountId" (change)="refresh()">
    </div>
    <div class="form-group m-2">
        <label for="f3">insuranceCompanyId</label>
        <input class="form-control" id="f3" [(ngModel)]="insuranceCompanyId" (change)="refresh()">
    </div>
    <div class="form-group m-2">
        <label for="f4">roleType</label>
        <input class="form-control" id="f4" [(ngModel)]="roleType" (change)="refresh()">
    </div>
</div>
<div>
    <table mat-table class="table table-bordered" [dataSource]="dataSource" matSort>
        <!-- Position Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> メールアドレス </th>
            <td mat-cell *matCellDef="let userType"> {{userType.email}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="accountId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> accountId </th>
            <td mat-cell *matCellDef="let userType"> {{userType.accountId}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="accountName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> accountName </th>
            <td mat-cell *matCellDef="let userType"> {{userType.accountName}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="insuranceCompanyId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> insuranceCompanyId </th>
            <td mat-cell *matCellDef="let userType"> {{userType.insuranceCompanyId}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="linkageType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> linkageType </th>
            <td mat-cell *matCellDef="let userType"> {{userType.linkageType}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="roleType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> roleType </th>
            <td mat-cell *matCellDef="let userType"> {{userType.roleType}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let userType">
                <button class="float-right btn btn-sm m-1 btn-info"
                        (click)="remove(userType.userName)">
                    <i class="fas fa-trash-alt"></i>
                </button>
                <button class="float-right btn btn-sm m-1 btn-info"
                        (click)="edit(userType.userName)">
                    <i class="fas fa-align-justify"></i>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[25, 50, 100]"
                   showFirstLastButtons
                   aria-label="Select page of periodic userTypes">
    </mat-paginator>
</div>

<div class="row justify-content-center m-1">
    <h1 (click)="add()"><i class="fas fa-plus-circle"></i></h1>
</div>
<div class="row justify-content-center">
    ユーザー追加
</div>
<ng-template #nodata>
    <h4 class="m-2">ありません</h4>
</ng-template>


