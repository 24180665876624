import { EstimateRepository } from "./estimate.repository";
import { formatDate, formatNumber, } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { saveAs } from 'file-saver';
import { DocumentExportModel } from "./documentExport.model";

const baseUrl = environment.baseServerUrl;
const excelUrl = baseUrl + '/api/excel';

@Injectable()
export class DocumentExportRepository {
  publishDate: Date = new Date();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private repo: EstimateRepository,
    private http: HttpClient) { }

  transformDate(date?: Date) {
    if (date) {
      return formatDate(date, 'yyyy年MM月dd日', this.locale);
    }
    return "";
  }

  transformNumber(number?: number, format?: string) {
    number = number ?? 0;
    return format ? formatNumber(number, this.locale, format) : formatNumber(number, this.locale);
  }

  // generate pdf or excel document model
  generateCommonDocumentModel(space: string = ''): DocumentExportModel {
    return new DocumentExportModel(this.repo.currentInitialCost.name);
  }

  getQuotationExcel() {
    const documentModel = this.generateCommonDocumentModel();
    this.http.post(excelUrl, documentModel, { responseType: 'blob' }).subscribe(
      response => this.downLoadFile(response, "application/octet-stream", `${documentModel.name}.xlsx`),
      e => console.log(e));
  }

  downLoadFile(data: any, type: string, filename: string) {
    let blob = new Blob([data], { type: type });
    saveAs(blob, filename);
  }
}
