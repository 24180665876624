<h4 mat-dialog-title class="bg-info text-white p-2">
  項目追加マスタの{{additionalItemMaster.additionalItemMasterId == 0 ? "追加" : "編集" }}
</h4>
<div mat-dialog-content>
  <div *ngIf="detailsForm.invalid" class="validation-summary">
    <ul>
      <li *ngIf="name.invalid" class="text-danger">
        内訳費用項目を入力してください
      </li>
      <li *ngIf="cost.invalid" class="text-danger">
        金額を入力してください
      </li>
    </ul>
  </div>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>内訳費用項目</label>
      <input #name="ngModel" name="name" class="form-control"
             [(ngModel)]="additionalItemMaster.name" required />
      <div *ngIf="name.invalid" class="text-danger">
        内訳費用項目を入力してください
      </div>
    </div>
    <div class="form-group">
      <label>金額</label>
      <input #cost="ngModel" name="cost" type="number" class="form-control"
             [(ngModel)]="additionalItemMaster.cost" required />
      <div *ngIf="cost.invalid" class="text-danger">
        金額を入力してください
      </div>
    </div>
    <div class="form-group">
      <label>備考</label>
      <input #address="ngModel" name="remarks" class="form-control"
             [(ngModel)]="additionalItemMaster.remarks" />
    </div>
    <div class="form-group">
      <label>表示／非表示</label>
      <div class="custom-control custom-switch">
        <input #visible="ngModel" id="visible" name="visible" type="checkbox" class="custom-control-input"
               [(ngModel)]="additionalItemMaster.visible" />
        <label class="custom-control-label" for="visible">
          表示
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>作成日時</label>
      {{ additionalItemMaster.createdDate | date: 'medium'}}
    </div>
    <div class="form-group">
      <label>更新日時</label>
      {{ additionalItemMaster.updatedDate | date: 'medium'}}
    </div>
    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
