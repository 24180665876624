export class AdminCreateUser {
    constructor(
        public email?: string,
        public temporaryPassword?: string,
        public accountId?: string,
        public accountName?: string,
        public insuranceCompanyId?: string,
        public linkageType?: string,
        public roleType?: string,
    ) { }
}