import { AdditionalItemMaster } from "./additionalItemMaster.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

const baseUrl = environment.baseServerUrl;
const additionalItemMastersUrl = baseUrl + "/api/additionalitemmasters";

@Injectable()
export class AdditionalItemMasterRepository {
  additionalItemMasters: AdditionalItemMaster[];

  constructor(private http: HttpClient) { }

  getAdditionalItemMasters() {
    this.http.get<AdditionalItemMaster[]>(`${additionalItemMastersUrl}`)
      .subscribe(details => {
        this.additionalItemMasters = details;
      });
  }

  getAdditionalItemMaster(id: number) {
    this.http.get<AdditionalItemMaster[]>(`${additionalItemMastersUrl}/${id}`)
      .subscribe(details => {
        this.additionalItemMasters = details;
      });
  }

  insertAdditionalItemMaster(additionalItemMaster: AdditionalItemMaster): Observable<any> {
    return this.http.post(`${additionalItemMastersUrl}`, additionalItemMaster);
  }

  replaceAdditionalItemMaster(additionalItemMaster: AdditionalItemMaster): Observable<any> {
    return this.http.put(`${additionalItemMastersUrl}/${additionalItemMaster.additionalItemMasterId}`, additionalItemMaster);
  }

  deleteAdditionalItemMaster(additionalItemMaster: AdditionalItemMaster): Observable<any> {
    return this.http.delete(`${additionalItemMastersUrl}/${additionalItemMaster.additionalItemMasterId}`);
  }
}
