import { SentenceMaster } from "./sentenceMaster.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

const baseUrl = environment.baseServerUrl;
const sentenceMastersUrl = baseUrl + "/api/sentencemasters";

@Injectable()
export class SentenceMasterRepository {
  sentenceMasters: SentenceMaster[];
  visibleSentenceMasters: SentenceMaster[];

  constructor(private http: HttpClient) { }

  getVisibleLogoMasters() {
    this.http.get<SentenceMaster[]>(`${sentenceMastersUrl}?all=false`)
      .subscribe(details => {
        this.visibleSentenceMasters = details;
      });
  }

  getSentenceMasters() {
    this.http.get<SentenceMaster[]>(`${sentenceMastersUrl}`)
      .subscribe(details => {
        this.sentenceMasters = details;
      });
  }

  getSentenceMaster(id: number) {
    this.http.get<SentenceMaster[]>(`${sentenceMastersUrl}/${id}`)
      .subscribe(details => {
        this.sentenceMasters = details;
      });
  }

  insertSentenceMaster(sentenceMaster: SentenceMaster): Observable<any> {
    return this.http.post(`${sentenceMastersUrl}`, sentenceMaster);
  }

  replaceSentenceMaster(sentenceMaster: SentenceMaster): Observable<any> {
    return this.http.put(`${sentenceMastersUrl}/${sentenceMaster.sentenceMasterId}`, sentenceMaster);
  }

  deleteSentenceMaster(sentenceMaster: SentenceMaster): Observable<any> {
    return this.http.delete(`${sentenceMastersUrl}/${sentenceMaster.sentenceMasterId}`);
  }
}
