<h4 mat-dialog-title class="bg-info text-white p-2">
  固定項目マスタの{{fixedItemMaster.fixedItemMasterId == 0 ? "追加" : "編集" }}
</h4>
<div mat-dialog-content>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>内訳費用項目</label>
      <input #name="ngModel" name="name" class="form-control"
             [(ngModel)]="fixedItemMaster.name" required />
      <div *ngIf="name.invalid" class="text-danger">
        内訳費用項目を入力してください
      </div>
    </div>
    <div class="form-group">
      <label>金額</label>
      <input #cost="ngModel" name="cost" type="number" class="form-control"
             [(ngModel)]="fixedItemMaster.cost" required />
      <div *ngIf="cost.invalid" class="text-danger">
        金額を入力してください
      </div>
      <div class="custom-control custom-switch">
        <input #isPayByDays="ngModel" id="isPayByDays" name="isPayByDays" type="checkbox" class="custom-control-input"
               [(ngModel)]="fixedItemMaster.isPayByDays" />
        <label class="custom-control-label" for="isPayByDays">
          日割
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>備考</label>
      <input #address="ngModel" name="remarks" class="form-control"
             [(ngModel)]="fixedItemMaster.remarks" />
    </div>
    <div class="form-group">
      <label>表示／非表示</label>
      <div class="custom-control custom-switch">
        <input #visible="ngModel" id="visible" name="visible" type="checkbox" class="custom-control-input"
               [(ngModel)]="fixedItemMaster.visible" />
        <label class="custom-control-label" for="visible">
          表示
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>作成日時</label>
      {{ fixedItemMaster.createdDate | date: 'medium'}}
    </div>
    <div class="form-group">
      <label>更新日時</label>
      {{ fixedItemMaster.updatedDate | date: 'medium'}}
    </div>
    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
