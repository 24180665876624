import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LogoMasterRepository } from "../models/logoMaster.repository";
import { LogoMaster } from '../models/logoMaster.model';
import { environment } from '../../environments/environment';

@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'logoMasterModal.component.html',
})
export class LogoMasterModalComponent {
  logoMaster: LogoMaster;
  fileToUpload: File = null;
  url: string | ArrayBuffer = null;
  imageBaseUrl = `${environment.baseServerUrl}/api/logomasters/image/`;

  constructor(
    private repo: LogoMasterRepository,
    public dialogRef: MatDialogRef<LogoMasterModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: LogoMaster) {
    this.logoMaster = new LogoMaster(
      data.logoMasterId,
      data.logo,
      data.visible,
      data.order,
      data.createdDate,
      data.updatedDate);
  }

  get ImageSrc() {
    return this.url ? this.url : `${this.imageBaseUrl}${this.logoMaster.logo}`;
  }

  get ShowImage() {
    return this.ImageSrc == this.imageBaseUrl ? false : true;
  }

  handleFileInput(files: FileList) {
    if (files && files[0]) {
      this.fileToUpload = files.item(0);
      var reader = new FileReader();
      reader.readAsDataURL(files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const formData = new FormData();
    formData.append('formFile', this.fileToUpload);

    const details = this.repo.logoMasters;
    let matched = details.find(p => p.logoMasterId == this.logoMaster.logoMasterId);
    if (matched) {
      matched.visible = this.logoMaster.visible;
      matched.order = this.logoMaster.order || 0;
      matched.createdDate = this.logoMaster.createdDate;
      formData.set("logoMasterData", JSON.stringify(matched));
      this.repo.replaceLogoMaster(matched.logoMasterId, formData)
        .subscribe(() => this.repo.getLogoMasters());
    } else {
      const formData = new FormData();
      formData.set("logoMasterData", JSON.stringify(this.logoMaster));
      formData.append('formFile', this.fileToUpload);
      this.repo.insertLogoMaster(formData)
        .subscribe(() => this.repo.getLogoMasters());
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
