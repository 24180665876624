<div mat-dialog-title>

  <div class="red-color text-white p-1">
    <estimate-property-title (toggleClicked)="toggle()" (saveClicked)="fillTreeData()" (getPdfClicked)="getPdfs($event)"
      (getExcelClicked)="getExcel($event)"></estimate-property-title>
  </div>
</div>
<mat-dialog-content>
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side">
      <div class="input-group">
        <select [ngModel]="filterName" (change)="onSearchSelectChange($event.target.value)">
          <option class="dropdown-item" value="staffName">担当者名</option>
          <option class="dropdown-item" value="customerName">顧客名</option>
        </select>
        <input [ngModel]="keyword" name="keyword" class="form-control" (input)="onSearchChange($event.target.value)" />
      </div>
      <div class="container">
        <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl"
          class="history-tree mat-tree-position">
          <!-- Without Children -->
          <mat-tree-node *matTreeNodeDef="let node">
            <li class="mat-tree-node">
              <button mat-icon-button disabled></button>
              <a [routerLink]=""
                (click)="getInitialCostDetailHistory(node.id, node.customerId, node.name, node.staffName, node.customerName)">
                <i class="fa fa-history" aria-hidden="true" [title]="getDateString(node.name)"></i> {{node.description}}
              </a>
            </li>
          </mat-tree-node>

          <!-- With Children -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
            <li>
              <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.Name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                {{node.description}}：{{node.name}}
              </div>
              <ul [class.history-tree-invisible]="!nestedTreeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
              </ul>
            </li>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="col" id="current-initial-cost-content">
        <estimate-property></estimate-property>
        <estimate-sentence-items></estimate-sentence-items>
        <estimate-scheduled-contract></estimate-scheduled-contract>
        <estimate-fixed-items></estimate-fixed-items>
        <estimate-additional-items></estimate-additional-items>
        <div>⭐️{{retry_count}}</div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-dialog-content>