import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { InitialCost } from '../models/InitialCost.model';
import { EstimateRepository } from "../models/estimate.repository";
import Decimal from "decimal.js";

@Component({
  selector: "estimate-scheduled-contract",
  templateUrl: "scheduledContract.component.html"
})
export class ScheduledContractComponent {
  @Input() readonly = false;
  constructor(private repo: EstimateRepository,
    private router: Router,
    private activeRoute: ActivatedRoute) { }

  get scheduledContractDate(): Date {
    return this.repo.currentInitialCost ? this.repo.currentInitialCost.scheduledContractDate : null;;
  }

  get initialTotalCost(): Decimal {
    if (this.repo.currentInitialCost) {
      return this.repo.currentInitialCost.fixedItems
        ? this.repo.currentInitialCost.fixedItems.reduce((sum, current) =>
          sum.plus(this.repo.paymentByDays(current)), new Decimal(0))
        : new Decimal(0);
    }
    return new Decimal(0);
  }

  get initialCost(): InitialCost {
    return this.repo.currentInitialCost;
  }

  onChange(e: any) {
    this.repo.currentInitialCost.scheduledContractDate = new Date(e);
    this.repo.updateInitialCost(this.repo.currentInitialCost);
  }
}
