import { InitialCost } from "./InitialCost.model";
import { FixedItem } from "./fixedItem.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { AdditionalItem } from "./additionalItem.model";
import { forkJoin } from "rxjs";
import { SentenceItem } from "./sentenceItem.model";
import { AuthModel } from "./auth.model";
import Decimal from "decimal.js";
import { AnonymousRequestHttpClient } from "../core/anonymous-request/anonymous-request-http-client";

const baseUrl = environment.baseServerUrl;
const initialcostsUrl = baseUrl + "/api/initialcosts";
const fixedItemsUrl = baseUrl + "/api/fixeditems";
const additionalItemsUrl = baseUrl + "/api/additionalitems";
const sentenceItemsUrl = baseUrl + "/api/sentenceitems";

const initialcosthistoryUrl = baseUrl + "/api/Initialcosthistories";
const fixeditemhistoryUrl = baseUrl + "/api/fixeditemhistories";
const additionalitemhistoryUrl = baseUrl + "/api/additionalitemhistories";
const sentenceitemhistoryUrl = baseUrl + "/api/sentenceitemhistories";

const loginUrl = baseUrl + "/api/account/login";
const logoutUrl = baseUrl + "/api/account/logout";

const initialCostBucketName = environment.initialCostBucketName;
const initialCostPathName = environment.initialCostPathName;
const initialcostS3Url = `https://${initialCostBucketName}.s3.amazonaws.com/${initialCostPathName}`;

@Injectable()
export class EstimateRepository {

  date: Date = new Date(2021, 10, 15);

  initialCosts: InitialCost[];
  currentInitialCost: InitialCost;

  public objectUrl: string;
  public isJsonFileExist: boolean = true;

  constructor(
    private http: HttpClient,
    private anonymousRequestHttpClient: AnonymousRequestHttpClient,
  ) { }

  get customerId() {
    //TODO APIからの実装にへんこうすること
    return "1";
  }

  paymentByDays(fixedItem: FixedItem): Decimal {
    let cost = new Decimal(fixedItem.cost || 0);
    if (fixedItem.isPayByDays) {
      const lastDay = new Date(this.currentInitialCost.scheduledContractDate.getFullYear(),
        this.currentInitialCost.scheduledContractDate.getMonth() + 1, 0).getDate();
      const rate = new Decimal((lastDay - this.currentInitialCost.scheduledContractDate.getDate() + 1) / lastDay);
      return cost.times(rate);
    }
    return cost;
  }

  getInitialCostsFromMaster() {
    this.http.get<InitialCost[]>(`${initialcostsUrl}/master`).subscribe(m => this.initialCosts = m);
  }

  getCurrentInitialCostFromMaster(id: string, useS3Json: boolean = false) {
    this.http.get<InitialCost>(`${initialcostsUrl}/master/${id}`)
      .subscribe(data => {
        let initialCost = data;
        initialCost.customerId = this.customerId;
        initialCost.scheduledContractDate = this.currentInitialCost ? this.currentInitialCost.scheduledContractDate : this.date;
        this.createInitialCost(initialCost).subscribe((newInitialCost) => {
          this.currentInitialCost = newInitialCost;
          this.currentInitialCost.scheduledContractDate = new Date(newInitialCost.scheduledContractDate);
          this.fillInitialCostDetailsFromMaster();
          if (useS3Json) {
            this.getCurrentInitialCostFromS3(id, this.customerId);
          }
        });
      });
  }

  fillInitialCostDetailsFromMaster() {
    let fixedItemMasters = this.http.get<FixedItem[]>(`${fixedItemsUrl}/master`);
    let additionalItemMasters = this.http.get<AdditionalItem[]>(`${additionalItemsUrl}/master`);
    let sentences = this.http.get<SentenceItem[]>(`${sentenceItemsUrl}/master`);
    forkJoin([fixedItemMasters, additionalItemMasters, sentences]).subscribe(results => {
      // results[0] is our fixedItems
      // results[1] is our additionalItems
      // results[2] is our sentenceItems
      results[0].forEach(d => {
        d.fixedItemId = 0;
        d.customerId = this.customerId;
        d.guid = this.guid;
        d.initialCostId = this.currentInitialCost.initialCostId;
        d.options.forEach(o => o.fixedItemOptionId = 0);
      });
      this.currentInitialCost.fixedItems = results[0];

      results[1].forEach(d => {
        d.additionalItemId = 0;
        d.customerId = this.customerId;
        d.guid = this.guid;
        d.initialCostId = this.currentInitialCost.initialCostId;
      });
      this.currentInitialCost.additionalItems = results[1];

      results[2].forEach(d => {
        d.sentenceItemId = 0;
        d.customerId = this.customerId;
        d.guid = this.guid;
        d.initialCostId = this.currentInitialCost.initialCostId;
      });
      this.currentInitialCost.sentenceItems = results[2];

      this.createFixedItems(this.currentInitialCost.fixedItems);
      this.createAdditionalItems(this.currentInitialCost.additionalItems);
      this.createSentenceItems(this.currentInitialCost.sentenceItems);
    });
  }

  getCurrentInitialCost(id: string, customerId: string, useS3Json: boolean = false) {
    this.http.get<InitialCost>(`${initialcostsUrl}/${id}/${customerId}`)
      .subscribe(data => {
        if (data) {
          this.currentInitialCost = data;
          this.currentInitialCost.scheduledContractDate = new Date(data.scheduledContractDate);
          this.getFixedItems();
          this.getAdditionalItems();
          this.getSentenceItems();
          if (useS3Json) {
            this.getCurrentInitialCostFromS3(id, customerId);
          }
        } else {
          this.getCurrentInitialCostFromMaster(id, useS3Json);
        }
      });
  }

  resetCurrentInitialCost() {
    let fixedItemMasters = this.http.delete(`${fixedItemsUrl}/${this.currentInitialCost.initialCostId}`);
    let additionalItemMasters = this.http.delete(`${additionalItemsUrl}/${this.currentInitialCost.initialCostId}`);
    let sentences = this.http.delete(`${sentenceItemsUrl}/${this.currentInitialCost.initialCostId}`);

    forkJoin([fixedItemMasters, additionalItemMasters, sentences]).subscribe(results => {
      //this.getCurrentInitialCost(this.currentInitialCost.initialCostId, this.customerId);
      this.fillInitialCostDetailsFromMaster();
    });
  }

  get guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  //#region InitialCost

  getInitialCost(initialCostId: string) {
    this.http.get<InitialCost>(`${initialcostsUrl}/${initialCostId}/${this.customerId}`)
      .subscribe(data => {
        const fixedItems = this.currentInitialCost.fixedItems;
        const additionalItems = this.currentInitialCost.additionalItems;
        const sentenceItems = this.currentInitialCost.sentenceItems;

        this.currentInitialCost = data;
        this.currentInitialCost.scheduledContractDate = new Date(data.scheduledContractDate);

        this.currentInitialCost.fixedItems = fixedItems;
        this.currentInitialCost.additionalItems = additionalItems;
        this.currentInitialCost.sentenceItems = sentenceItems;
      });
  }

  createInitialCost(initialCost: InitialCost): Observable<InitialCost> {
    return this.http.post(`${initialcostsUrl}`, initialCost)
  }

  updateInitialCost(initialCost: InitialCost) {
    this.http.put(`${initialcostsUrl}/${initialCost.initialCostId}`, initialCost)
      .subscribe(() => this.getInitialCost(this.currentInitialCost.initialCostId));
  }

  removeInitialCost(initialCost: InitialCost) {
    this.http.delete(`${initialcostsUrl}/${initialCost.initialCostId}/${initialCost.customerId}`)
      .subscribe(() => this.getInitialCost(this.currentInitialCost.initialCostId));
  }

  //#region FixedItem

  getTemporaryFixedItem(fixedItemId: number): Observable<FixedItem> {
    return this.http.get<FixedItem>(`${fixedItemsUrl}/${this.currentInitialCost.initialCostId}/${this.currentInitialCost.customerId}/${fixedItemId}`)
  }

  getFixedItems() {
    this.http.get<FixedItem[]>(`${fixedItemsUrl}/${this.currentInitialCost.initialCostId}/${this.currentInitialCost.customerId}`)
      .subscribe(data => {
        this.currentInitialCost.fixedItems = data?.map(d => {
          d.guid = this.guid;
          return d;
        });
      });
  }

  createFixedItem(fixedItem: FixedItem) {
    this.http.post(`${fixedItemsUrl}`, fixedItem)
      .subscribe(() => this.getFixedItems());
  }

  createFixedItems(fixedItems: FixedItem[]) {
    if (fixedItems) {
      this.http.post(`${fixedItemsUrl}/bulk`, fixedItems)
        .subscribe(() => this.getFixedItems());
    }
  }

  updateFixedItem(fixedItem: FixedItem) {
    this.http.put(`${fixedItemsUrl}/${fixedItem.fixedItemId}`, fixedItem)
      .subscribe(() => this.getFixedItems());
  }

  removeFixedItem(fixedItem: FixedItem) {
    this.http.delete(`${fixedItemsUrl}/${fixedItem.fixedItemId}/${fixedItem.customerId}`)
      .subscribe(() => this.getFixedItems());
  }

  removeFixedItems(initialCostId: string) {
    this.http.delete(`${fixedItemsUrl}/${initialCostId}`)
      .subscribe(() => this.getFixedItems());
  }

  //#endregion

  //#region AdditionalItem

  getAdditionalItems() {
    this.http.get<AdditionalItem[]>(`${additionalItemsUrl}/${this.currentInitialCost.initialCostId}/${this.currentInitialCost.customerId}`)
      .subscribe(data => {
        this.currentInitialCost.additionalItems = data?.map(d => {
          d.guid = this.guid;
          return d;
        });
      });
  }

  createAdditionalItem(additionalItem: AdditionalItem) {
    this.http.post(`${additionalItemsUrl}`, additionalItem)
      .subscribe(() => this.getAdditionalItems());
  }

  createAdditionalItems(additionalItems: AdditionalItem[]) {
    if (additionalItems) {
      this.http.post(`${additionalItemsUrl}/bulk`, additionalItems)
        .subscribe(() => this.getAdditionalItems());
    }
  }

  updateAdditionalItem(additionalItem: AdditionalItem) {
    this.http.put(`${additionalItemsUrl}/${additionalItem.additionalItemId}`, additionalItem)
      .subscribe(() => this.getAdditionalItems());
  }

  removeAdditionalItem(additionalItem: AdditionalItem) {
    this.http.delete(`${additionalItemsUrl}/${additionalItem.additionalItemId}/${additionalItem.customerId}`)
      .subscribe(() => this.getAdditionalItems());
  }

  removeAdditionalItems(initialCostId: string) {
    this.http.delete(`${additionalItemsUrl}/${initialCostId}`)
      .subscribe(() => this.getAdditionalItems());
  }

  //#endregion

  //#region SentenceItem

  getSentenceItems() {
    this.http.get<SentenceItem[]>(`${sentenceItemsUrl}/${this.currentInitialCost.initialCostId}/${this.currentInitialCost.customerId}`)
      .subscribe(data => {
        this.currentInitialCost.sentenceItems = data?.map(d => {
          d.guid = this.guid;
          return d;
        });
      });
  }

  createSentenceItem(sentenceItem: SentenceItem) {
    this.http.post(`${sentenceItemsUrl}`, sentenceItem)
      .subscribe(() => this.getSentenceItems());
  }

  createSentenceItems(sentenceItems: SentenceItem[]) {
    if (sentenceItems) {
      this.http.post(`${sentenceItemsUrl}/bulk`, sentenceItems)
        .subscribe(() => this.getSentenceItems());
    }
  }

  updateSentenceItem(sentenceItem: SentenceItem) {
    this.http.put(`${sentenceItemsUrl}/${sentenceItem.sentenceItemId}`, sentenceItem)
      .subscribe(() => this.getSentenceItems());
  }

  removeSentenceItem(sentenceItem: SentenceItem) {
    this.http.delete(`${sentenceItemsUrl}/${sentenceItem.sentenceItemId}/${sentenceItem.customerId}`)
      .subscribe(() => this.getSentenceItems());
  }

  removeSentenceItems(initialCostId: string) {
    this.http.delete(`${sentenceItemsUrl}/${initialCostId}`)
      .subscribe(() => this.getSentenceItems());
  }

  //#endregion

  //#region history

  getInitialCostHistories(id: string, customerId: string): Observable<any> {
    return this.http.get(`${initialcosthistoryUrl}/${id}/${customerId}`);
  }

  getInitialCostHistory(id: string, customerId: string, version: Date, staffName: string, customerName: string) {
    this.http.get<InitialCost>(`${initialcosthistoryUrl}/${id}/${customerId}/${version}/${staffName}/${customerName}`)
      .subscribe(data => {
        if (data == null) {
          return;
        }
        const currentXmin = this.currentInitialCost.xmin;
        this.currentInitialCost = data;
        this.currentInitialCost.xmin = currentXmin;
        this.currentInitialCost.scheduledContractDate = new Date(this.currentInitialCost.scheduledContractDate);
        this.fillInitialCostDetailsFromHistories(version);
      });
  }

  fillInitialCostDetailsFromHistories(version: Date) {
    let fixedItemMasters = this.http.get<FixedItem[]>(`${fixeditemhistoryUrl}/${this.currentInitialCost.initialCostId}/${this.currentInitialCost.customerId}/${version}/${this.currentInitialCost.staffName}/${this.currentInitialCost.customerName}`);
    let additionalItemMasters = this.http.get<AdditionalItem[]>(`${additionalitemhistoryUrl}/${this.currentInitialCost.initialCostId}/${this.currentInitialCost.customerId}/${version}/${this.currentInitialCost.staffName}/${this.currentInitialCost.customerName}`);
    let sentences = this.http.get<SentenceItem[]>(`${sentenceitemhistoryUrl}/${this.currentInitialCost.initialCostId}/${this.currentInitialCost.customerId}/${version}/${this.currentInitialCost.staffName}/${this.currentInitialCost.customerName}`);
    forkJoin([fixedItemMasters, additionalItemMasters, sentences]).subscribe(results => {
      // results[0] is our fixedItems
      // results[1] is our additionalItems
      // results[2] is our sentenceItems
      results[0].forEach(d => {
        d.guid = this.guid;
      });
      this.currentInitialCost.fixedItems = results[0];

      results[1].forEach(d => {
        d.guid = this.guid;
      });
      this.currentInitialCost.additionalItems = results[1];

      results[2].forEach(d => {
        d.guid = this.guid;
      });
      this.currentInitialCost.sentenceItems = results[2];

      this.saveCurrentInitialCost();
    });
  }

  createInitialCostHistory(): Observable<any> {
    return this.http.post(`${initialcosthistoryUrl}`, this.currentInitialCost)
  }

  saveCurrentInitialCost() {
    let fixedItemMasters = this.http.delete(`${fixedItemsUrl}/${this.currentInitialCost.initialCostId}`);
    let additionalItemMasters = this.http.delete(`${additionalItemsUrl}/${this.currentInitialCost.initialCostId}`);
    let sentences = this.http.delete(`${sentenceItemsUrl}/${this.currentInitialCost.initialCostId}`);

    forkJoin([fixedItemMasters, additionalItemMasters, sentences]).subscribe(results => {
      this.currentInitialCost.fixedItems?.forEach(f => f.options?.forEach(o => o.fixedItemOptionId = 0));
      this.updateInitialCost(this.currentInitialCost);
      this.createFixedItems(this.currentInitialCost.fixedItems);
      this.createAdditionalItems(this.currentInitialCost.additionalItems);
      this.createSentenceItems(this.currentInitialCost.sentenceItems);
    });
  }

  //#endregion

  //#region login

  login(name: string, password: string): Observable<AuthModel> {
    return this.http.post<AuthModel>(loginUrl, {
      name: name, password: password
    });
  }

  logout() {
    this.http.post(logoutUrl, null)
      .subscribe(response => { });
  }

  //#endregion

  //#region S3 json storage

  getCurrentInitialCostFromS3(id: string, customerId: string) {
    this.objectUrl = `${initialcostS3Url}/${id}_${customerId}.json`;
    this.anonymousRequestHttpClient.get<InitialCost>(this.objectUrl)
      .subscribe(data => {
        if (data) {
          this.currentInitialCost = data;
          this.currentInitialCost.scheduledContractDate = new Date(data.scheduledContractDate);
          this.isJsonFileExist = true;
        }
      }, e => { this.isJsonFileExist = false; });
  }

  //#endregion
}
