import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FixedItemMasterRepository } from "../models/fixedItemMaster.repository";
import { FixedItemMaster } from '../models/fixedItemMaster.model';

@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'fixedItemMasterModal.component.html',
})
export class FixedItemMasterModalComponent {
  fixedItemMaster: FixedItemMaster;

  constructor(
    private repo: FixedItemMasterRepository,
    public dialogRef: MatDialogRef<FixedItemMasterModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: FixedItemMaster) {
    this.fixedItemMaster = new FixedItemMaster(
      data.fixedItemMasterId,
      data.name,
      data.cost,
      data.remarks,
      data.isPayByDays,
      data.visible,
      data.order,
      data.createdDate,
      data.updatedDate);
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const details = this.repo.fixedItemMasters;
    let matched = details.find(p => p.fixedItemMasterId == this.fixedItemMaster.fixedItemMasterId);
    if (matched) {
      matched.name = this.fixedItemMaster.name;
      matched.cost = this.fixedItemMaster.cost;
      matched.remarks = this.fixedItemMaster.remarks;
      matched.isPayByDays = this.fixedItemMaster.isPayByDays;
      matched.visible = this.fixedItemMaster.visible;
      matched.order = this.fixedItemMaster.order || 0;
      matched.createdDate = this.fixedItemMaster.createdDate;
      this.repo.replaceFixedItemMaster(matched)
        .subscribe(() => this.repo.getFixedItemMasters());
    } else {
      this.repo.insertFixedItemMaster(this.fixedItemMaster)
        .subscribe(() => this.repo.getFixedItemMasters());
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
