import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { InitialCost } from '../../models/InitialCost.model';

@Injectable()
export class AnonymousRequestHttpClient {
  // Inject HttpBackend
  constructor(private httpBackend: HttpBackend) { }

  get<T>(url: string) {
    // Create a new http client
    const newHttpClient = new HttpClient(this.httpBackend);
    return newHttpClient.get<T>(url);
  }
}
