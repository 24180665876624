import { Component, Input } from "@angular/core";
import { EstimateRepository } from "../models/estimate.repository";
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SentenceItem } from '../models/sentenceItem.model';
import { SentenceMasterRepository } from "../models/sentenceMaster.repository";
import { SentenceMaster } from "../models/sentenceMaster.model";
import { SentencePosition } from "../models/sentencePosition.model";
import { SentenceItemsModalComponent } from "./sentenceItemsModal.component";
import { InitialCost } from "../models/InitialCost.model";
import { Subject } from "rxjs";
import { SpinnerService } from "../shared/spinner/spinner.service";

@Component({
  selector: "estimate-sentence-items",
  templateUrl: "sentenceItems.component.html",
  styleUrls: ['sentenceItems.component.css']
})
export class SentenceItemsComponent {
  @Input() readonly = false;
  public panelOpenState = false;
  public color = 'primary';
  public mode = 'indeterminate';
  public value = 25;
  public diameter = 50;
  public isLoading: Subject<boolean> = this.spinnerService.isSentenceItemsLoading;

  constructor(private repo: EstimateRepository,
    private sentenceMasterRepository: SentenceMasterRepository,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private spinnerService: SpinnerService) {
    this.sentenceMasterRepository.getVisibleLogoMasters();
  }

  get hasData() {
    return this.repo.currentInitialCost?.sentenceItems?.length > 0;
  }

  sentenceItems(sentencePosition: SentencePosition): SentenceItem[] {
    return this.repo.currentInitialCost.sentenceItems.filter(s => s.position == sentencePosition);
  }

  public get sentencePosition(): typeof SentencePosition {
    return SentencePosition;
  }

  updateSentenceItem(guid: string, sentencePosition: SentencePosition) {
    const sentenceItems = this.repo.currentInitialCost.sentenceItems;
    sentenceItems.filter(s => s.guid != guid && s.position == sentencePosition).forEach(s => s.isSelected = false);
    this.repo.updateSentenceItem(sentenceItems.find(s => s.guid == guid));
  }

  edit(sentenceItem: SentenceItem) {
    const ref: MatDialogRef<SentenceItemsModalComponent> = this.dialog.
      open(SentenceItemsModalComponent, {
        data: sentenceItem,
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }
}
