export class UserType {
    constructor(
        public userName?: string,
        public email?: string,
        public accountId?: string,
        public accountName?: string,
        public insuranceCompanyId?: string,
        public linkageType?: string,
        public roleType?: string,
        public createdDate?: Date,
        public updatedDate?: Date
    ) { }
}
