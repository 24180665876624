<h4 mat-dialog-title class="bg-info text-white p-2">
  項目追加マスタの{{logoMaster.logoMasterId == 0 ? "追加" : "編集" }}
</h4>
<div mat-dialog-content>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>ロゴ</label>
      <input type="file"
             name="logo"
             class="form-control pb-sm-5"
             accept="image/*"
             (change)="handleFileInput($event.target.files)">
      <img *ngIf="ShowImage" [src]="ImageSrc" height="200" class="m-3" />
    </div>
    <div class="form-group">
      <label>表示／非表示</label>
      <div class="custom-control custom-switch">
        <input #visible="ngModel" id="visible" name="visible" type="checkbox" class="custom-control-input"
               [(ngModel)]="logoMaster.visible" />
        <label class="custom-control-label" for="visible">
          表示
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>作成日時</label>
      {{ logoMaster.createdDate | date: 'medium'}}
    </div>
    <div class="form-group">
      <label>更新日時</label>
      {{ logoMaster.updatedDate | date: 'medium'}}
    </div>
    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
