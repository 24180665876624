<div class="container">
  <div class="row">
    <div class="col-sm col-2">
      <span class="text-nowrap">
        <mat-icon class="menu" *ngIf="!readonly" (click)="onToggleClicked()">
          menu
        </mat-icon>
        {{initialCost?.name}}<ng-container *ngIf="readonly">（aws S3のjsonデータから取得）</ng-container>
      </span>
    </div>
    <div class="col-sm">
      <button class="float-right menu-button" (click)="closeDialog()"><span class="text-nowrap">閉じる</span></button>
    </div>
    <ng-container *ngIf="!readonly">
      <div class="col-sm">
        <button class="float-right menu-button" [disabled]="!canEdit" (click)="onGetPdfClicked(initialCost?.name)"><span class="text-nowrap">PDF</span></button>
      </div>
      <div class="col-sm">
        <button class="float-right menu-button" [disabled]="!canEdit" (click)="onGetExcelClicked(initialCost?.name)"><span class="text-nowrap">Excel</span></button>
      </div>
      <div class="col-sm">
        <button class="float-right menu-button" [disabled]="!canEdit" (click)="reset()"><span class="text-nowrap">編集前に戻す</span></button>
      </div>
      <div class="col-sm">
        <button class="float-right menu-button" [disabled]="!canEdit" (click)="onSaveClicked()"><span class="text-nowrap">登録</span></button>
      </div>
      <div class="col-sm">
        <button class="float-right menu-button" [disabled]="!canEdit" (click)="navigate()"><span class="text-nowrap">マスター</span></button>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-sm">
      <b>初期費用概算</b>
    </div>
  </div>
</div>
