<h4 mat-dialog-title class="bg-info text-white p-2">
    ユーザーの{{adminUpdateUserAttributes == null ? "追加" : "編集" }}
</h4>
<div mat-dialog-content>
    <form novalidate #detailsForm="ngForm" *ngIf="adminCreateUser != null">
        <div class="form-group">
            <label>メールアドレス</label>
            <input #email="ngModel" name="adminCreateUser.email" class="form-control"
                   [(ngModel)]="adminCreateUser.email" required />
            <div *ngIf="email.invalid" class="text-danger">
                メールアドレスを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>一時パスワード</label>
            <input #temporaryPassword="ngModel" name="adminCreateUser.temporaryPassword" class="form-control"
                   [(ngModel)]="adminCreateUser.temporaryPassword" required />
            <div *ngIf="temporaryPassword.invalid" class="text-danger">
                一時パスワードスを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>accountId</label>
            <input #accountId="ngModel" name="adminCreateUser.accountId" class="form-control"
                   [(ngModel)]="adminCreateUser.accountId" required />
            <div *ngIf="accountId.invalid" class="text-danger">
                accountIdを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>accountName</label>
            <input #accountName="ngModel" name="adminCreateUser.accountName" class="form-control"
                   [(ngModel)]="adminCreateUser.accountName" required />
            <div *ngIf="accountName.invalid" class="text-danger">
                accountNameを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>insuranceCompanyId</label>
            <input #insuranceCompanyId="ngModel" name="adminCreateUser.insuranceCompanyId" class="form-control"
                   [(ngModel)]="adminCreateUser.insuranceCompanyId" required />
            <div *ngIf="insuranceCompanyId.invalid" class="text-danger">
                insuranceCompanyIdを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>linkageType</label>
            <input #linkageType="ngModel" name="adminCreateUser.linkageType" class="form-control"
                   [(ngModel)]="adminCreateUser.linkageType" required />
            <div *ngIf="linkageType.invalid" class="text-danger">
                linkageTypeを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>roleType</label>
            <input #roleType="ngModel" name="adminCreateUser.roleType" class="form-control"
                   [(ngModel)]="adminCreateUser.roleType" required />
            <div *ngIf="roleType.invalid" class="text-danger">
                roleTypeを入力してください
            </div>
        </div>
        <div class="text-center pt-2">
            <button type="button" class="btn btn-outline-danger m-1"
                    [disabled]="detailsForm.invalid"
                    (click)="onOkClick()">
                登録
            </button>
            <button type="button" class="btn btn-outline-danger m-1"
                    (click)="onNoClick()">
                戻る
            </button>
        </div>
    </form>
    <form novalidate #detailsForm="ngForm" *ngIf="adminUpdateUserAttributes != null">
        <div class="form-group">
            <label>メールアドレス</label>
            <input #email="ngModel" name="adminUpdateUserAttributes.email" class="form-control"
                   [(ngModel)]="adminUpdateUserAttributes.email" required />
            <div *ngIf="email.invalid" class="text-danger">
                メールアドレスを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>accountName</label>
            <input #accountName="ngModel" name="adminUpdateUserAttributes.accountName" class="form-control"
                   [(ngModel)]="adminUpdateUserAttributes.accountName" required />
            <div *ngIf="accountName.invalid" class="text-danger">
                accountNameを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>insuranceCompanyId</label>
            <input #insuranceCompanyId="ngModel" name="adminUpdateUserAttributes.insuranceCompanyId" class="form-control"
                   [(ngModel)]="adminUpdateUserAttributes.insuranceCompanyId" required />
            <div *ngIf="insuranceCompanyId.invalid" class="text-danger">
                insuranceCompanyIdを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>linkageType</label>
            <input #linkageType="ngModel" name="adminUpdateUserAttributes.linkageType" class="form-control"
                   [(ngModel)]="adminUpdateUserAttributes.linkageType" required />
            <div *ngIf="linkageType.invalid" class="text-danger">
                linkageTypeを入力してください
            </div>
        </div>
        <div class="form-group">
            <label>roleType</label>
            <input #roleType="ngModel" name="adminUpdateUserAttributes.roleType" class="form-control"
                   [(ngModel)]="adminUpdateUserAttributes.roleType" required />
            <div *ngIf="roleType.invalid" class="text-danger">
                roleTypeを入力してください
            </div>
        </div>
        <div class="text-center pt-2">
            <button type="button" class="btn btn-outline-danger m-1"
                    [disabled]="detailsForm.invalid"
                    (click)="onOkClick()">
                登録
            </button>
            <button type="button" class="btn btn-outline-danger m-1"
                    (click)="onNoClick()">
                戻る
            </button>
        </div>
    </form>
</div>
