<h1 mat-dialog-title class="red-color text-white p-2">
  項目{{sentenceItem.sentenceItemId == 0 ? "追加" : "編集" }}
</h1>
<div mat-dialog-content>
  <div *ngIf="detailsForm.invalid" class="validation-summary">
    <ul>
      <li *ngIf="sentence.invalid" class="text-danger">
        文言を入力してください
      </li>
    </ul>
  </div>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>文言</label>
      <textarea #sentence="ngModel" name="sentence" class="form-control" rows="5"
                [(ngModel)]="sentenceItem.sentence" required></textarea>
      <div *ngIf="sentence.invalid" class="text-danger">
        文言を入力してください
      </div>
    </div>

    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
