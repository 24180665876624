import { SentencePosition } from './sentencePosition.model';

export class SentenceMaster {
  constructor(
    public sentenceMasterId?: number,
    public sentence?: string,
    public position?: SentencePosition,
    public visible?: boolean,
    public order?: number,
    public createdDate?: Date,
    public updatedDate?: Date,
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string
  ) { }

  static get positions(): PositionOption[] {
    return [
      new PositionOption("挨拶①", SentencePosition.sentence1),
      new PositionOption("挨拶②", SentencePosition.sentence2)
    ];
  }
}

export class PositionOption {
  constructor(public name: string, public value: number) { }
}
