<h1 mat-dialog-title class="red-color text-white p-2">
  項目{{fixedItem.fixedItemId == 0 ? "追加" : "編集" }}
</h1>
<div mat-dialog-content>
  <div *ngIf="detailsForm.invalid" class="validation-summary">
    <ul>
      <li *ngIf="name.invalid" class="text-danger">
        内訳費用項目を入力してください
      </li>
      <li *ngIf="cost.invalid" class="text-danger">
        金額を入力してください
      </li>
    </ul>
  </div>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>内訳費用項目</label>
      <input #name="ngModel" name="name" class="form-control"
             [(ngModel)]="fixedItem.name" required />
      <div *ngIf="name.invalid" class="text-danger">
        内訳費用項目を入力してください
      </div>
    </div>
    <div *ngIf="fixedItem.options?.length > 0" class="form-group">
      <label>ステータス</label>
      <select #status="ngModel" name="status" class="form-control"
              [(ngModel)]="fixedItem.status"
              (change)="updateFixedItem(fixedItem)">
        <option *ngFor="let option of fixedItem.options"
                [value]="option.fixedItemOptionStatus">
          {{optionName(option.fixedItemOptionStatus)}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>単位</label>
      <input #name="ngModel" name="unit" class="form-control"
             [(ngModel)]="fixedItem.unit"
             (blur)="updateFixedItem(fixedItem)" />
    </div>
    <div class="form-group">
      <label>金額</label>
      <input #cost="ngModel" name="cost" type="number" class="form-control"
             [(ngModel)]="fixedItem.cost" required />
      <div *ngIf="cost.invalid" class="text-danger">
        金額を入力してください
      </div>
      <div class="custom-control custom-switch">
        <input #isPayByDays="ngModel" id="isPayByDays" name="isPayByDays" type="checkbox" class="custom-control-input"
               [(ngModel)]="fixedItem.isPayByDays" />
        <label class="custom-control-label" for="isPayByDays">
          日割
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>備考</label>
      <input #address="ngModel" name="remarks" class="form-control"
             [(ngModel)]="fixedItem.remarks" />
    </div>
    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
