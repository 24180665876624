<table cdkDropList *ngIf="logoMasters?.length > 0; else nodata" class="table table-bordered table-responsive drag-and-drop-list" (cdkDropListDropped)="drop($event)">
  <thead><tr><th>ロゴ</th><th class="nowrap"></th></tr></thead>
  <tbody>
    <ng-template ngFor let-logoMaster [ngForOf]="logoMasters">
      <tr cdkDrag class="m-1 p-2 p-1t drag-and-drop-box"
          [class.bg-secondary]="!logoMaster.visible"
          [class.bg-light]="logoMaster.visible">
        <td class="col-md nowrap"><img [src]="getImageSrc(logoMaster)" class="logoImage m-1" loading="lazy" /></td>
        <td class="col-md col-min-width">
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!logoMaster.visible" [class.btn-info]="logoMaster.visible"
                  (click)="remove(logoMaster)">
            <i class="fas fa-trash-alt"></i>
          </button>
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!logoMaster.visible" [class.btn-info]="logoMaster.visible"
                  (click)="edit(logoMaster)">
            <i class="fas fa-align-justify"></i>
          </button>
        </td>
      <tr>
    </ng-template>
  </tbody>
</table>
<div class="row justify-content-center m-1">
  <h1 (click)="add()"><i class="fas fa-plus-circle"></i></h1>
</div>
<div class="row justify-content-center">
  項目追加
</div>
<ng-template #nodata>
  <h4 class="m-2">ありません</h4>
</ng-template>


