import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Amplify Configuration
import { I18n } from "aws-amplify";
import { amplifyVocabularies } from './app/localize/amplify.vocabularies.const';

I18n.putVocabularies(amplifyVocabularies);
I18n.setLanguage("ja");
// End Amplify Configuration

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, { providers: [{ provide: LOCALE_ID, useValue: 'ja-JP' }] })
    .catch(err => console.error(err));
