import Decimal from "decimal.js";

export class AdditionalItem {
  constructor(
    public additionalItemId?: number,
    public customerId?: string,
    public name?: string,
    public cost?: Decimal,
    public remarks?: string,
    public initialCostId?: string,
    public guid?: string,
    public order?: number,
    public canDelete?: boolean,
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string,
    public createdDate?: Date,
    public updatedDate?: Date) { }
}
