import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import Decimal from 'decimal.js';
import { EstimateRepository } from "../models/estimate.repository";
import { FixedItem } from '../models/fixedItem.model';
import { FixedItemOptionStatus } from '../models/fixedItemOptionStatus.model';


@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'fixedItemsModal.component.html',
})
export class FixedItemsModalComponent {
  fixedItem: FixedItem;

  constructor(
    private repo: EstimateRepository,
    public dialogRef: MatDialogRef<FixedItemsModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: FixedItem) {
    this.fixedItem = new FixedItem(
      data.fixedItemId,
      data.customerId,
      data.name,
      data.status,
      data.unit,
      data.cost,
      data.isPayByDays,
      data.remarks,
      data.initialCostId,
      data.guid,
      data.order,
      data.canDelete,
      data.options);
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const details = this.repo.currentInitialCost.fixedItems;
    let matched = details.find(p => p.guid == this.fixedItem.guid);
    if (matched) {
      matched.name = this.fixedItem.name;
      matched.status = this.fixedItem.status;
      matched.unit = this.fixedItem.unit;
      matched.cost = this.fixedItem.cost;
      matched.isPayByDays = this.fixedItem.isPayByDays;
      matched.remarks = this.fixedItem.remarks;
      this.repo.updateFixedItem(matched);
    } else {
      this.fixedItem.order = details.length + 1;
      this.repo.currentInitialCost.fixedItems.push(this.fixedItem);
      this.repo.createFixedItem(this.fixedItem);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  optionName(value: number) {
    switch (value) {
      case 0:
        return "なし";
      case 1:
        return "月数";
      case 2:
        return "金額";
      case 3:
        return "割合";
      case 4:
        return "家賃に含む";
    }
  }

  updateFixedItem(fixedItem: FixedItem) {
    const numberPattern = /\d+/g;
    this.repo.getTemporaryFixedItem(fixedItem.fixedItemId).subscribe(f => {
      if (f) {
        const numberPart = Number(((fixedItem.unit || '').match(numberPattern) || []).join(''));
        switch (fixedItem.status) {
          // case "3":
          //   fixedItem.unit = numberPart + "%";
          //   fixedItem.remarks = "1か月分の" + numberPart + "%";
          //   fixedItem.cost = f.cost.times(new Decimal(numberPart)).div(new Decimal(100));
          //   break;
          // case "1":
          //   fixedItem.unit = numberPart.toString();
          //   fixedItem.remarks = numberPart + "か月分";
          //   fixedItem.cost = f.cost.times(new Decimal(numberPart));
          //   break;
          default:
            fixedItem.unit = "";
            fixedItem.remarks = "";
            fixedItem.cost = f.cost;
            break;
        }
      }
    });
  }
}
