<table cdkDropList *ngIf="colorMasters?.length > 0; else nodata" class="table table-bordered drag-and-drop-list" (cdkDropListDropped)="drop($event)">
  <thead><tr><th>色</th><th></th></tr></thead>
  <tbody>
    <ng-template ngFor let-colorMaster [ngForOf]="colorMasters">
      <tr cdkDrag class="m-1 p-2 p-1t drag-and-drop-box"
          [class.bg-secondary]="!colorMaster.visible"
          [class.bg-light]="colorMaster.visible">
        <td class="col-md">
          <span class="color-code">{{ colorMaster.color }}</span>
          <input matInput [ngxMatColorPicker]="picker" #color="ngModel" name="color" disabled hidden
                 [(ngModel)]="colorMaster.color" required pattern="^#(?:[0-9a-fA-F]{3}){1,2}$">
          <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
          <ngx-mat-color-picker #picker touchUi=true [color]="colorMaster.color"></ngx-mat-color-picker>
        </td>
        <td class="col-md  col-min-width">
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!colorMaster.visible" [class.btn-info]="colorMaster.visible"
                  (click)="remove(colorMaster)">
            <i class="fas fa-trash-alt"></i>
          </button>
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!colorMaster.visible" [class.btn-info]="colorMaster.visible"
                  (click)="edit(colorMaster)">
            <i class="fas fa-align-justify"></i>
          </button>
        </td>
      <tr>
    </ng-template>
  </tbody>
</table>
<div class="row justify-content-center m-1">
  <h1 (click)="add()"><i class="fas fa-plus-circle"></i></h1>
</div>
<div class="row justify-content-center">
  項目追加
</div>
<ng-template #nodata>
  <h4 class="m-2">ありません</h4>
</ng-template>


