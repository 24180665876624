import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { AuthModel } from "./auth.model";

const baseUrl = environment.baseServerUrl;
const url = baseUrl + "/api/account";

@Injectable()
export class AccountRepository {
  constructor(private http: HttpClient) { }

  authenticate(idToken: string): Observable<AuthModel> {
    return this.http.post<any>(url, { idToken: idToken })
  }
}
