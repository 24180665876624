<table cdkDropList *ngIf="sentenceMasters?.length > 0; else nodata" class="table table-bordered table-responsive drag-and-drop-list" (cdkDropListDropped)="drop($event)">
  <thead><tr><th>文言</th><th class="nowrap">表示場所</th><th></th></tr></thead>
  <tbody>
    <ng-template ngFor let-sentenceMaster [ngForOf]="sentenceMasters">
      <tr cdkDrag class="m-1 p-2 p-1t drag-and-drop-box"
          [class.bg-secondary]="!sentenceMaster.visible"
          [class.bg-light]="sentenceMaster.visible">
        <td class="col-md nowrap" ><p class="pre-wrap-cr"> {{ sentenceMaster.sentence }}</p></td>
        <td class="col-md nowrap"> {{ positionName(sentenceMaster) }}</td>
        <td class="col-md col-min-width">
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!sentenceMaster.visible" [class.btn-info]="sentenceMaster.visible"
                  (click)="remove(sentenceMaster)">
            <i class="fas fa-trash-alt"></i>
          </button>
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!sentenceMaster.visible" [class.btn-info]="sentenceMaster.visible"
                  (click)="edit(sentenceMaster)">
            <i class="fas fa-align-justify"></i>
          </button>
        </td>
      <tr>
    </ng-template>
  </tbody>
</table>
<div class="row justify-content-center m-1">
  <h1 (click)="add()"><i class="fas fa-plus-circle"></i></h1>
</div>
<div class="row justify-content-center">
  項目追加
</div>
<ng-template #nodata>
  <h4 class="m-2">ありません</h4>
</ng-template>


