import { Component, Input } from "@angular/core";
import { EstimateRepository } from "../models/estimate.repository";
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AdditionalItem } from '../models/additionalItem.model';
import { AdditionalItemsModalComponent } from '../estimate/additionalItemsModal.component';
import { InitialCost } from '../models/InitialCost.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from "rxjs";
import { SpinnerService } from "../shared/spinner/spinner.service";
import Decimal from "decimal.js";

@Component({
  selector: "estimate-additional-items",
  templateUrl: "additionalItems.component.html",
  styleUrls: ['additionalItems.component.css']
})
export class AdditionalItemsComponent {
  @Input() readonly = false;
  public panelOpenState = false;
  public color = 'primary';
  public mode = 'indeterminate';
  public value = 25;
  public diameter = 50;
  public isLoading: Subject<boolean> = this.spinnerService.isAdditionalItemsLoading;

  constructor(private repo: EstimateRepository,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private spinnerService: SpinnerService) { }

  get initialCost(): InitialCost {
    return this.repo.currentInitialCost;
  }

  get initialTotalCost(): Decimal {
    if (this.repo.currentInitialCost) {
      return this.repo.currentInitialCost.additionalItems
        ? this.repo.currentInitialCost.additionalItems.reduce((sum, current) =>
          sum.plus(current.cost), new Decimal(0))
        : new Decimal(0);
    }
    return new Decimal(0);
  }

  get additionalItems(): AdditionalItem[] {
    if (this.repo.currentInitialCost) {
      return this.repo.currentInitialCost.additionalItems;
    }
    return null;
  }

  add(initialCostId: string) {
    const ref: MatDialogRef<AdditionalItemsModalComponent> = this.dialog.
      open(AdditionalItemsModalComponent, {
        data: new AdditionalItem(0, this.repo.customerId, "", null, "", initialCostId, this.repo.guid, 0, true),
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  edit(additionalItem: AdditionalItem) {
    const ref: MatDialogRef<AdditionalItemsModalComponent> = this.dialog.
      open(AdditionalItemsModalComponent, {
        data: additionalItem,
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  remove(additionalItem: AdditionalItem) {
    const details = this.repo.currentInitialCost.additionalItems;
    const index = details.indexOf(additionalItem);
    if (index > -1) {
      details.splice(index, 1);
      this.repo.removeAdditionalItem(additionalItem);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    let allItems = this.additionalItems
    const from = event.previousIndex;
    const to = event.currentIndex;
    const current = allItems[from];
    moveItemInArray(allItems, from, to);

    // Computing a new orderValue for the object that was moved
    let lowerBound = 0.0;
    // Is there an object before it in the array?
    if (to > 0) {
      lowerBound = allItems[to - 1].order;
    } else {
      lowerBound = allItems[1].order - 2.0;
    }
    let upperBound = 0.0;
    // Is there an object after it in the array?
    if (to < allItems.length - 1) {
      upperBound = allItems[to + 1].order;
    } else {
      upperBound = allItems[to - 1].order + 2.0;
    }
    const newOrderValue = (lowerBound + upperBound) / 2.0;

    current.order = newOrderValue;
    this.repo.updateAdditionalItem(current);
  }
}
