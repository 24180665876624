import { Color } from '@angular-material-components/color-picker';

export class ColorMaster {
  public r: number;
  public g: number;
  public b: number;
  public a: number;

  constructor(
    public colorMasterId?: number,
    public color?: Color,
    public visible?: boolean,
    public order?: number,
    public createdDate?: Date,
    public updatedDate?: Date,
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string
  ) { }
}
