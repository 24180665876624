<div mat-dialog-title>
  <div class="red-color text-white p-1">
    <estimate-property-title [readonly]="true"></estimate-property-title>
  </div>
</div>
<mat-dialog-content>
  <div class="col" id="current-initial-cost-content">
    <div class="card">
      <div class="card-body">
        <p>S3に保存したjsonを使用（バックグラウンドのWebApiをバイパスします。）</p>
        <p *ngIf="isJsonFileExist">
          S3のオブジェクトURL:<a class="float-right menu-button" [href]="objectUrl" target="_blank">{{objectUrl}}</a>を開く
        </p>
        <div *ngIf="!isJsonFileExist">
          <p>S3のオブジェクトURLにjsonがありません。ボタンをクリックして「登録」ボタンをクリックしてください。</p>
          <button class="float-right menu-button" (click)="reopenDialog()">{{name}}をWebApi経由で開く</button>
        </div>
      </div>
    </div>
    <estimate-property [readonly]="true"></estimate-property>
    <estimate-sentence-items [readonly]="true"></estimate-sentence-items>
    <estimate-scheduled-contract [readonly]="true"></estimate-scheduled-contract>
    <estimate-fixed-items [readonly]="true"></estimate-fixed-items>
    <estimate-additional-items [readonly]="true"></estimate-additional-items>
  </div>
</mat-dialog-content>


