<h4 mat-dialog-title class="bg-info text-white p-2">
  カラーマスタの{{colorMaster.colorMasterId == 0 ? "追加" : "編集" }}
</h4>
<div mat-dialog-content>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>カラー</label>
      <input matInput [ngxMatColorPicker]="picker" #color="ngModel" name="color" class="form-control mb-1"
              [(ngModel)]="colorMaster.color" required pattern="^#(?:[0-9a-fA-F]{3}){1,2}$">
      <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
      <ngx-mat-color-picker #picker touchUi=true [color]="colorMaster.color"></ngx-mat-color-picker>
    </div> 
    <div class="form-group">
      <label>表示／非表示</label>
      <div class="custom-control custom-switch">
        <input #visible="ngModel" id="visible" name="visible" type="checkbox" class="custom-control-input"
               [(ngModel)]="colorMaster.visible" />
        <label class="custom-control-label" for="visible">
          表示
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>作成日時</label>
      {{ colorMaster.createdDate | date: 'medium'}}
    </div>
    <div class="form-group">
      <label>更新日時</label>
      {{ colorMaster.updatedDate | date: 'medium'}}
    </div>
    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
