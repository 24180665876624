import { Component } from "@angular/core";
import { FixedItemMasterRepository } from "../models/fixedItemMaster.repository";

@Component({
    templateUrl: "master.component.html"
})
export class MasterComponent {
    constructor(
        private repo: FixedItemMasterRepository) {
        this.repo.getInitialCostMasters();
    }
}
