<table cdkDropList *ngIf="additionalItemMasters?.length > 0; else nodata" class="table table-bordered table-responsive drag-and-drop-list" (cdkDropListDropped)="drop($event)">
  <thead><tr><th>項目名</th><th>金額</th><th class="nowrap">補足</th><th></th></tr></thead>
  <tbody>
    <ng-template ngFor let-additionalItemMaster [ngForOf]="additionalItemMasters">
      <tr cdkDrag class="m-1 p-2 p-1t drag-and-drop-box"
          [class.bg-secondary]="!additionalItemMaster.visible"
          [class.bg-light]="additionalItemMaster.visible">
        <td class="col-md"> {{ additionalItemMaster.name }}</td>
        <td class="col-md"> {{ additionalItemMaster.cost | number:'1.0-0'}}円</td>
        <td class="col-md nowrap"> {{ additionalItemMaster.remarks }}</td>
        <td class="col-md col-min-width">
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!additionalItemMaster.visible" [class.btn-info]="additionalItemMaster.visible"
                  (click)="remove(additionalItemMaster)">
            <i class="fas fa-trash-alt"></i>
          </button>
          <button class="float-right btn btn-sm m-1" [class.bg-secondary]="!additionalItemMaster.visible" [class.btn-info]="additionalItemMaster.visible"
                  (click)="edit(additionalItemMaster)">
            <i class="fas fa-align-justify"></i>
          </button>
        </td>
      <tr>
    </ng-template>
  </tbody>
</table>
<div class="row justify-content-center m-1">
  <h1 (click)="add()"><i class="fas fa-plus-circle"></i></h1>
</div>
<div class="row justify-content-center">
  項目追加
</div>
<ng-template #nodata>
  <h4 class="m-2">ありません</h4>
</ng-template>


