import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EstimateRepository } from '../models/estimate.repository';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Inject } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { TreeData } from './tree/tree-data.model';
import { TreeDataService } from './tree/tree-data.service';
import { of as observableOf } from 'rxjs';
import { DocumentExportRepository } from '../models/documentExport.repositoery';
import { InitilaCostS3ModalComponent } from './awss3/initialCostS3Modal.component';
import * as jsonData from '../../assets/data.json';

@Component({
  templateUrl: './initialCostModal.component.html',
  styleUrls: ['./initialCostModal.component.css']
})
export class InitilaCostModalComponent implements OnInit {
  @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;
  @Input("position") position: string = "start";
  nestedTreeControl: NestedTreeControl<TreeData>;
  nestedDataSource: MatTreeNestedDataSource<TreeData>;
  id: string;
  keyword: string;
  filterName: string;
  dataFromServer: TreeData[];
  awss3: false;
  data: any = jsonData;

  constructor(
    private matSnackBar: MatSnackBar,
    private dataService: TreeDataService,
    private repo: EstimateRepository,
    private documentExportRepository: DocumentExportRepository,
    public dialogRef: MatDialogRef<InitilaCostModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.id = data.id;
    this.filterName = "staffName";
    this.awss3 = data.awss3
  }

  ngOnInit() {
    this.nestedTreeControl = new NestedTreeControl<TreeData>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.dataService._dataChange.subscribe(
      data => {
        this.dataFromServer = data;
        (this.nestedDataSource.data = this.filterTreeData(data));
      }
    );
    const id = this.id
    if (id) {
      this.repo.getCurrentInitialCost(id, this.repo.customerId);
    }
  }

  get retry_count() {
    return this.data.default.retry_count;
  }

  private _getChildren = (node: TreeData) => observableOf(node.children);
  hasNestedChild = (_: number, nodeData: TreeData) => nodeData.children.length > 0;

  refreshTreeData() {
    const data = this.dataFromServer;
    this.nestedDataSource.data = null;
    this.nestedDataSource.data = this.filterTreeData(data);
  }

  onSearchSelectChange(filterName: string) {
    this.filterName = filterName;
    this.refreshTreeData();
  }

  onSearchChange(keyword: string) {
    this.keyword = keyword;
    this.refreshTreeData();
  }

  filterTreeData(source: TreeData[]): TreeData[] {
    if (!this.keyword) {
      return source;
    }
    if (!source) {
      return source;
    }
    if (source.length == 0) {
      return source;
    }

    switch (this.filterName) {
      case "staffName":
        return source.filter(sn => sn.name.indexOf(this.keyword) > -1);
      case "customerName":
        const jsonString = JSON.stringify(source);
        let result = JSON.parse(jsonString) as TreeData[];
        result.forEach(sn => sn.children = sn.children.filter(cn => cn.name.indexOf(this.keyword) > -1));
        result = result.filter(sn => sn.children.length > 0);
        return result;
      default:
        return source;
    }
  }

  getInitialCostDetailHistory(id: string, userId: string, version: Date, staffName: string, customerName: string) {
    this.repo.getInitialCostHistory(id, userId, version, staffName, customerName);
  }

  fillTreeData() {
    if (this.repo.currentInitialCost) {
      this.repo.getInitialCostHistories(this.repo.currentInitialCost.initialCostId, this.repo.currentInitialCost.customerId)
        .subscribe(data => {
          this.dataService.treeData = data;
          this.refreshTreeData();
          this.open();
          if (this.awss3) {
            setTimeout(() => this.reopenDialog(), 2000);
          }
        });
    }
  }

  open() {
    if (this.repo.currentInitialCost) {
      this.repo.getInitialCostHistories(this.repo.currentInitialCost.initialCostId, this.repo.currentInitialCost.customerId)
        .subscribe(data => {
          const canOpen = data && data.length > 0;
          this.dataService.treeData = data;
          this.refreshTreeData();
          if (canOpen) {
            this.sidenav.open();
          } else {
            this.matSnackBar.open("履歴がありません。", null, {
              duration: 5000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ['animate__animated', 'animate__slideInLeft']
            });
          }
        });
    }
  }

  close() {
    this.sidenav.close();
  }

  toggle() {
    if (this.sidenav.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  reopenDialog() {
    this.dialogRef.close();

    const ref: MatDialogRef<InitilaCostS3ModalComponent> = this.dialog.
      open(InitilaCostS3ModalComponent, {
        data: this.id,
        width: "95%",
        maxHeight: "60%",
        position: { top: '5%' },
        hasBackdrop: true,
        panelClass: ['animate__animated', 'animate__slideInLeft']
      });
  }

  getDateString(date: string) {
    const dt = new Date(date);
    return dt.toLocaleString("ja-JP");
  }

  get currentInitialCost() {
    return this.repo.currentInitialCost;
  }

  getPdfs(fileName: string) {
    const data = document.getElementById('current-initial-cost-content');

    let HTML_Width = data.getBoundingClientRect().width;
    let HTML_Height = data.getBoundingClientRect().height;
    let top_left_margin = 15;
    let PDF_Width = HTML_Width + (top_left_margin * 2);
    let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    let canvas_image_width = HTML_Width;
    let canvas_image_height = HTML_Height;

    let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas(data, {
      useCORS: true,
      allowTaint: true
    }).then(function (canvas) {
      canvas.getContext('2d');

      console.log(canvas.height + "  " + canvas.width);

      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height], true);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height, 'FAST');
      }

      pdf.save(`${fileName}.pdf`);
    });
  }

  getExcel(fileName: string) {
    this.documentExportRepository.getQuotationExcel();
  }
}
