import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

@NgModule({
  imports: [RouterModule, FormsModule, CommonModule, MaterialModule],
  declarations: [],
  providers: [],
  exports: []
})
export class AuthModeule { }
