import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AdditionalItemMasterRepository } from "../models/additionalItemMaster.repository";
import { AdditionalItemMaster } from '../models/additionalItemMaster.model';

@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'additionalItemMasterModal.component.html',
})
export class AdditionalItemMasterModalComponent {
  additionalItemMaster: AdditionalItemMaster;

  constructor(
    private repo: AdditionalItemMasterRepository,
    public dialogRef: MatDialogRef<AdditionalItemMasterModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: AdditionalItemMaster) {
    this.additionalItemMaster = new AdditionalItemMaster(
      data.additionalItemMasterId,
      data.name,
      data.cost,
      data.remarks,
      data.visible,
      data.order,
      data.createdDate,
      data.updatedDate);
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const details = this.repo.additionalItemMasters;
    let matched = details.find(p => p.additionalItemMasterId == this.additionalItemMaster.additionalItemMasterId);
    if (matched) {
      matched.name = this.additionalItemMaster.name;
      matched.cost = this.additionalItemMaster.cost;
      matched.remarks = this.additionalItemMaster.remarks;
      matched.visible = this.additionalItemMaster.visible;
      matched.order = this.additionalItemMaster.order || 0;
      matched.createdDate = this.additionalItemMaster.createdDate;
      this.repo.replaceAdditionalItemMaster(matched)
        .subscribe(() => this.repo.getAdditionalItemMasters());
    } else {
      this.repo.insertAdditionalItemMaster(this.additionalItemMaster)
        .subscribe(() => this.repo.getAdditionalItemMasters());
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
