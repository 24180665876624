import { InitialCostMaster } from "./InitialCostMaster.model";
import { FixedItemMaster } from "./fixedItemMaster.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

const baseUrl = environment.baseServerUrl;
const initialcostmastersUrl = baseUrl + "/api/initialcostmasters";
const fixedItemMastersUrl = baseUrl + "/api/fixeditemmasters";

@Injectable()
export class FixedItemMasterRepository {
  initialCostMaster: InitialCostMaster;
  initialCostMasters: InitialCostMaster[];
  fixedItemMasters: FixedItemMaster[];

  constructor(private http: HttpClient) { }

  getInitialCostMaster(id: string) {
    this.http.get<InitialCostMaster>(`${initialcostmastersUrl}/${id}`).subscribe(m => this.initialCostMaster = m);
  }

  getInitialCostMasters() {
    this.http.get<InitialCostMaster[]>(`${initialcostmastersUrl}`).subscribe(m => this.initialCostMasters = m);
  }

  getFixedItemMasters() {
    this.http.get<FixedItemMaster[]>(`${fixedItemMastersUrl}`)
      .subscribe(details => {
        this.fixedItemMasters = details;
      });
  }

  getFixedItemMaster(id: string) {
    this.http.get<FixedItemMaster[]>(`${fixedItemMastersUrl}/${id}`)
      .subscribe(details => {
        this.fixedItemMasters = details;
      });
  }

  insertInitialCostMaster(initialCostMaster: InitialCostMaster) {
    this.http.post(`${initialcostmastersUrl}`, initialCostMaster)
      .subscribe(() => this.getInitialCostMasters());
  }

  replaceInitialCostMaster(initialCostMaster: InitialCostMaster) {

    this.http.put(`${initialcostmastersUrl}/${initialCostMaster.initialCostMasterId}`, initialCostMaster)
      .subscribe(() => this.getInitialCostMasters());
  }

  deleteInitialCostMaster(initialCostMaster: InitialCostMaster) {
    this.http.delete(`${initialcostmastersUrl}/${initialCostMaster.initialCostMasterId}`)
      .subscribe(() => this.getInitialCostMasters());
  }

  insertFixedItemMaster(fixedItemMaster: FixedItemMaster): Observable<any> {
    return this.http.post(`${fixedItemMastersUrl}`, fixedItemMaster);
  }

  replaceFixedItemMaster(fixedItemMaster: FixedItemMaster): Observable<any> {
    return this.http.put(`${fixedItemMastersUrl}/${fixedItemMaster.fixedItemMasterId}`, fixedItemMaster);
  }

  deleteFixedItemMaster(fixedItemMaster: FixedItemMaster): Observable<any> {
    return this.http.delete(`${fixedItemMastersUrl}/${fixedItemMaster.fixedItemMasterId}`);
  }
}
