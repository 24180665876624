import { Component } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FixedItemMasterRepository } from "../models/fixedItemMaster.repository";
import { FixedItemMaster } from "../models/fixedItemMaster.model";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FixedItemMasterModalComponent } from './fixedItemMasterModal.component';

@Component({
  templateUrl: "fixedItemMaster.component.html",
  styleUrls: ['fixedItemMaster.component.css']
})
export class FixedItemMasterComponent {
  constructor(private repo: FixedItemMasterRepository, public dialog: MatDialog) {
    this.repo.getFixedItemMasters();
  }

  get fixedItemasters() {
    return this.repo.fixedItemMasters;
  }

  drop(event: CdkDragDrop<string[]>) {
    let allItems = this.repo.fixedItemMasters
    const from = event.previousIndex;
    const to = event.currentIndex;
    const current = allItems[from];
    moveItemInArray(allItems, from, to);

    // Computing a new orderValue for the object that was moved
    let lowerBound = 0.0;
    // Is there an object before it in the array?
    if (to > 0) {
      lowerBound = allItems[to - 1].order;
    } else {
      lowerBound = allItems[1].order - 2.0;
    }
    let upperBound = 0.0;
    // Is there an object after it in the array?
    if (to < allItems.length - 1) {
      upperBound = allItems[to + 1].order;
    } else {
      upperBound = allItems[to - 1].order + 2.0;
    }
    const newOrderValue = (lowerBound + upperBound) / 2.0;
  
    current.order = newOrderValue;
    this.repo.replaceFixedItemMaster(current)
      .subscribe(() => this.repo.getFixedItemMasters());
  }

  add() {
    const ref: MatDialogRef<FixedItemMasterModalComponent> = this.dialog.
      open(FixedItemMasterModalComponent, {
        data: new FixedItemMaster(0, "", null, "", true, true, 0),
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  edit(fixedItemMaster: FixedItemMaster) {
    const ref: MatDialogRef<FixedItemMasterModalComponent> = this.dialog.
      open(FixedItemMasterModalComponent, {
        data: fixedItemMaster,
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  remove(fixedItemMaster: FixedItemMaster) {
    const details = this.repo.fixedItemMasters;
    const index = details.indexOf(fixedItemMaster);
    if (index > -1) {
      this.repo.deleteFixedItemMaster(fixedItemMaster).subscribe(() => this.repo.getFixedItemMasters());
    }
  }
}
