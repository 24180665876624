import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'MockApp';
    accessToken: string = "";
    //userType: UserType;
    constructor(
        private httpBackend: HttpBackend) {
    }

    // ngOnInit() {
    //     Hub.listen('auth', this.listener);
    // }

    // listener(data: any) {
    //     switch (data.payload.event) {
    //         case 'signIn':
    //             let payload = data.payload.data.attributes;
    //             this.userType = new UserType(
    //                 payload.sub,
    //                 payload[StandardAttributes.email],
    //                 payload[StandardAttributes.accountId],
    //                 payload[StandardAttributes.accountName],
    //                 payload[StandardAttributes.insuranceCompanyId],
    //                 payload[StandardAttributes.inkageType],
    //                 payload[StandardAttributes.roleType]);
    //             break;
    //         case 'signOut':
    //             this.router.navigateByUrl("");
    //             break;
    //     }
    // }

    loginName(user: any) {
        return user.attributes.email;
    }

    getToken() {
        const TOKEN_ENDPOINT = 'https://mockapp-prod-prod.auth.ap-northeast-1.amazoncognito.com/oauth2/token'

        let body = new HttpParams()
            .set('grant_type', 'client_credentials')
            .set('client_id', "4jsq4ccdhitpfnq6psoqdbahrr")
            .set('client_secret', "1n7lf3ma48ivl42chr42keeubhhukue471q9a50ivabeqdcjv2bk");

        const http = new HttpClient(this.httpBackend);
        http.post(TOKEN_ENDPOINT, body).subscribe(response => {
            this.accessToken = (response as any).access_token;
            console.log(`accessToken: ${this.accessToken}`);
        }, e => console.log(e));
    }
}
