import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MasterComponent } from './master.component';
import { IntialCostMasterComponent } from './initialCostMasters.component';
import { InitialCostMasterEditorComponent } from './initialCostMasterEditor.component';
import { FixedItemMasterModalComponent } from './fixedItemMasterModal.component';
import { InitialCostMastersModalComponent } from './initialCostMastersModal.component';
import { FixedItemMasterComponent } from './fixedItemMaster.component';
import { AdditionalItemMasterComponent } from './additionalItemMaster.component';
import { AdditionalItemMasterModalComponent } from './additionalItemMasterModal.component';
import { ColorMasterComponent } from './colorMaster.component';
import { ColorMasterModalComponent } from './colorMasterModal.component';
import { LogoMasterComponent } from './logoMaster.component';
import { LogoMasterModalComponent } from './logoMasterModal.component';
import { SentenceMasterComponent } from './sentenceMaster.component';
import { SentenceMasterModalComponent } from './sentenceMasterModal.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { MaterialModule } from "../material.module";
import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import { CognitoListUserComponent } from "./cognitoListUser.component";
import { CognitoUserModalComponent } from "./cognitoUserModal.component";

const routes: Routes = [
    {
        path: "", component: MasterComponent,
        children: [
            { path: "fixeditemmaster", component: FixedItemMasterComponent },
            { path: "additionalitemmaster", component: AdditionalItemMasterComponent },
            { path: "colormaster", component: ColorMasterComponent },
            { path: "logomaster", component: LogoMasterComponent },
            { path: "sentencemaster", component: SentenceMasterComponent },
            { path: "initialcost", component: IntialCostMasterComponent },
            { path: "listuser", component: CognitoListUserComponent }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        RouterModule.forChild(routes),
        CommonModule,
        NgxMatColorPickerModule,
        MaterialModule,
        AmplifyAuthenticatorModule
    ],
    providers: [
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
    ],
    declarations: [
        MasterComponent,
        IntialCostMasterComponent,
        InitialCostMasterEditorComponent,
        InitialCostMastersModalComponent,
        FixedItemMasterComponent,
        FixedItemMasterModalComponent,
        AdditionalItemMasterComponent,
        AdditionalItemMasterModalComponent,
        ColorMasterComponent,
        ColorMasterModalComponent,
        LogoMasterComponent,
        LogoMasterModalComponent,
        SentenceMasterComponent,
        SentenceMasterModalComponent,
        CognitoListUserComponent,
        CognitoUserModalComponent
    ],
    exports: [IntialCostMasterComponent]
})
export class MasterModule { }
