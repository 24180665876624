import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SentenceMasterRepository } from "../models/sentenceMaster.repository";
import { PositionOption, SentenceMaster } from '../models/sentenceMaster.model';

@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'sentenceMasterModal.component.html',
})
export class SentenceMasterModalComponent {
  sentenceMaster: SentenceMaster;

  constructor(
    private repo: SentenceMasterRepository,
    public dialogRef: MatDialogRef<SentenceMasterModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: SentenceMaster) {
    this.sentenceMaster = new SentenceMaster(
      data.sentenceMasterId,
      data.sentence,
      data.position,
      data.visible,
      data.order,
      data.createdDate,
      data.updatedDate);
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const details = this.repo.sentenceMasters;
    let matched = details.find(p => p.sentenceMasterId == this.sentenceMaster.sentenceMasterId);
    if (matched) {
      matched.sentence = this.sentenceMaster.sentence;
      matched.position = this.sentenceMaster.position;
      matched.visible = this.sentenceMaster.visible;
      matched.order = this.sentenceMaster.order || 0;
      matched.createdDate = this.sentenceMaster.createdDate;
      this.repo.replaceSentenceMaster(matched)
        .subscribe(() => this.repo.getSentenceMasters());
    } else {
      this.repo.insertSentenceMaster(this.sentenceMaster)
        .subscribe(() => this.repo.getSentenceMasters());
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  get positions(): PositionOption[] {
    return SentenceMaster.positions;
  }

  comparePositions(s1: PositionOption, s2: PositionOption) {
    return s1 && s2 && s1.value == s2.value;
  }
}


