<div *ngIf="isLoading | async; else loaded">
  <mat-progress-spinner class="spinner" [diameter]="diameter" [color]="color" [mode]="mode" [value]="value"></mat-progress-spinner>
</div>
<ng-template #loaded>
  <mat-accordion class="m-3">
    <mat-expansion-panel [expanded]="panelOpenState">
      <mat-expansion-panel-header #panelH (click)="panelH._toggle();panelOpenState=!panelOpenState">
        <mat-panel-title>
          <i class="material-icons app-toolbar-menu m-2" (click)="panelH._toggle();panelOpenState=!panelOpenState">
            文言項目
          </i>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table *ngIf="hasData && sentenceItems(sentencePosition.sentence1)?.length > 0; else nodata" class="table table-bordered">
        <tbody>
          <ng-template ngFor let-sentenceItem [ngForOf]="sentenceItems(sentencePosition.sentence1)">
            <tr class="m-1 p-2 p-1t">
              <td class="col-md nowrap"><p class="pre-wrap-cr"><span [class.fs-4]="sentenceItem.sentence.length > 20">{{ sentenceItem.sentence }}</span></p></td>
              <td class="col-md col-min-width">
                <div class="round">
                  <input type="checkbox" [disabled]="readonly"
                         id="{{sentenceItem.guid}}"
                         name="sentence1"
                         [value]="sentenceItem.guid"
                         [(ngModel)]="sentenceItem.isSelected"
                         [checked]="sentenceItem.isSelected"
                         (change)="updateSentenceItem(sentenceItem.guid, sentencePosition.sentence1)">
                  <label for="{{sentenceItem.guid}}"></label>
                </div>
              </td>
              <td class="col-md col-min-width">
                <button *ngIf="!readonly" class="rate-button m-1" (click)="edit(sentenceItem)">
                  変更
                </button>
              </td>
            <tr>
          </ng-template>
        </tbody>
      </table>

      <table *ngIf="hasData && sentenceItems(sentencePosition.sentence2)?.length > 0; else nodata" class="table table-bordered">
        <tbody>
          <ng-template ngFor let-sentenceItem [ngForOf]="sentenceItems(sentencePosition.sentence2)">
            <tr class="m-1 p-2 p-1t">
              <td class="col-md nowrap"><p class="pre-wrap-cr"> {{ sentenceItem.sentence }}</p></td>
              <td class="col-md col-min-width">
                <div class="round">
                  <input type="checkbox" [disabled]="readonly"
                         id="{{sentenceItem.guid}}"
                         name="sentence1"
                         [value]="sentenceItem.guid"
                         [(ngModel)]="sentenceItem.isSelected"
                         [checked]="sentenceItem.isSelected"
                         (change)="updateSentenceItem(sentenceItem.guid, sentencePosition.sentence2)">
                  <label for="{{sentenceItem.guid}}"></label>
                </div>
              </td>
              <td class="col-md col-min-width">
                <button *ngIf="!readonly" class="rate-button m-1" (click)="edit(sentenceItem)">
                  変更
                </button>
              </td>
            <tr>
          </ng-template>
        </tbody>
      </table>

      <ng-template #nodata>
        <h4 class="m-2">ありません</h4>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
