import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { CognitoRepository } from "../models/cognito.repository";
import { UserType } from "../models/userType.model";
import { CognitoUserModalComponent } from "./cognitoUserModal.component";

@Component({
    templateUrl: "cognitoListUser.component.html",
    styleUrls: ['cognitoListUser.component.css']
})
export class CognitoListUserComponent implements AfterViewInit {
    email: string;
    accountId: string;
    insuranceCompanyId: string;
    roleType: string;

    displayedColumns: string[] = ['email', 'accountId', 'accountName', 'insuranceCompanyId', 'linkageType', 'roleType', 'button'];
    dataSource = new MatTableDataSource<UserType>([]);

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    constructor(private repo: CognitoRepository, public dialog: MatDialog) {
        this.refresh();
    }

    refresh() {
        this.repo.getListUser(this.email ?? "", this.accountId ?? "", this.insuranceCompanyId ?? "", this.roleType ?? "").subscribe(data => {
            this.dataSource.data = [];
            this.dataSource.data = data;
        });
    }

    add() {
        const ref: MatDialogRef<CognitoUserModalComponent> = this.dialog.
            open(CognitoUserModalComponent, {
                data: [new UserType(), this.dataSource.data],
                width: "600px",
                height: "620px",
                hasBackdrop: true
            });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.refresh();
            }
        });
    }

    edit(userName: string) {
        if ((userName ?? "").length > 0) {
            this.repo.getUser(userName).subscribe(user => {
                const ref: MatDialogRef<CognitoUserModalComponent> = this.dialog.
                    open(CognitoUserModalComponent, {
                        data: [user, this.dataSource.data],
                        width: "600px",
                        height: "620px",
                        hasBackdrop: true
                    });
                ref.afterClosed().subscribe(result => {
                    if (result) {
                        this.refresh();
                    }
                });
            });
        }
    }

    remove(userName: string) {
        if ((userName ?? "").length > 0) {
            this.repo.deleteUser(userName).subscribe(() => this.refresh());
        }
    }
}