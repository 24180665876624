import { TreeData } from './tree-data.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TreeDataService {
  _dataChange = new BehaviorSubject<TreeData[]>([]);

  set treeData(value: TreeData[]) {
    this._dataChange.next(value);
  }
}
