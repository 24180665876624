import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PropertyTitleComponent } from "./propertyTitle.component";
import { ScheduledContractComponent } from "./scheduledContract.component";
import { FixedItemsComponent } from "./fixedItems.component";
import { FixedItemsModalComponent } from "./fixedItemsModal.component";
import { AdditionalItemsComponent } from "./additionalItems.component";
import { AdditionalItemsModalComponent } from "./additionalItemsModal.component";
import { SentenceItemsComponent } from "./sentenceItems.component";
import { PropertyComponent } from "./property.component";
import { InitialCostListComponent } from "./initialCostList.component";
import { InitilaCostModalComponent } from "./initialCostModal.component";
import { InitilaCostS3ModalComponent } from "./awss3/initialCostS3Modal.component";
import { RouterModule } from "@angular/router";
import { SentenceItemsModalComponent } from "./sentenceItemsModal.component";
import { MaterialModule } from "../material.module";
import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";

@NgModule({
    declarations: [
        PropertyTitleComponent,
        ScheduledContractComponent,
        FixedItemsComponent,
        FixedItemsModalComponent,
        AdditionalItemsComponent,
        AdditionalItemsModalComponent,
        SentenceItemsComponent,
        SentenceItemsModalComponent,
        PropertyComponent,
        InitialCostListComponent,
        InitilaCostModalComponent,
        InitilaCostS3ModalComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule,
        MaterialModule,
        AmplifyAuthenticatorModule
    ],
    exports: [
        PropertyComponent,
        InitilaCostModalComponent,
        InitialCostListComponent,
        MaterialModule
    ]
})
export class EstimateModule { }
