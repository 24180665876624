import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InitilaCostModalComponent } from "./estimate/initialCostModal.component";
import { InitialCostListComponent } from "./estimate/initialCostList.component";
import { AuthModeule } from './auth/auth.module';

const routes: Routes = [
  {
    path: "master",
    loadChildren: () => import("./master/master.module").then(module => module.MasterModule)
  },
  { path: "", component: InitialCostListComponent },
  { path: "estimate/:id", component: InitilaCostModalComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AuthModeule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
