import { Component } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ColorMasterRepository } from "../models/colorMaster.repository";
import { ColorMaster } from "../models/colorMaster.model";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ColorMasterModalComponent } from './colorMasterModal.component';

@Component({
  templateUrl: "colorMaster.component.html",
  styleUrls: ['colorMaster.component.css']
})
export class ColorMasterComponent {
  constructor(private repo: ColorMasterRepository, public dialog: MatDialog) {
    this.repo.getColorMasters();
  }

  get colorMasters() {
    return this.repo.colorMasters;
  }

  drop(event: CdkDragDrop<string[]>) {
    let allItems = this.repo.colorMasters
    const from = event.previousIndex;
    const to = event.currentIndex;
    const current = allItems[from];
    moveItemInArray(allItems, from, to);

    // Computing a new orderValue for the object that was moved
    let lowerBound = 0.0;
    // Is there an object before it in the array?
    if (to > 0) {
      lowerBound = allItems[to - 1].order;
    } else {
      lowerBound = allItems[1].order - 2.0;
    }
    let upperBound = 0.0;
    // Is there an object after it in the array?
    if (to < allItems.length - 1) {
      upperBound = allItems[to + 1].order;
    } else {
      upperBound = allItems[to - 1].order + 2.0;
    }
    const newOrderValue = (lowerBound + upperBound) / 2.0;
  
    current.order = newOrderValue;
    this.repo.replaceColorMaster(current)
      .subscribe(() => this.repo.getColorMasters());
  }

  add() {
    const ref: MatDialogRef<ColorMasterModalComponent> = this.dialog.
      open(ColorMasterModalComponent, {
        data: new ColorMaster(0, null, true, 0),
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  edit(colorMaster: ColorMaster) {
    const ref: MatDialogRef<ColorMasterModalComponent> = this.dialog.
      open(ColorMasterModalComponent, {
        data: colorMaster,
        width: "600px",
        height: "500px",
        hasBackdrop: true
      });
  }

  remove(colorMaster: ColorMaster) {
    const details = this.repo.colorMasters;
    const index = details.indexOf(colorMaster);
    if (index > -1) {
      this.repo.deleteColorMaster(colorMaster).subscribe(() => this.repo.getColorMasters());
    }
  }
}
