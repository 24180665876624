<div class="navbar bg-info mb-1">
    <a class="navbar-brand text-white">マスター</a>
</div>
<div class="col-3 fixed-bottom mb-1">
    <a class="btn btn-block btn-secondary" routerLink="/">
        初期費用概算
    </a>
</div>
<div class="row no-gutters">
    <div class="col-3">
        <!-- <button class="btn btn-block btn-outline-info m-1" routerLink="/master/fixeditemmaster" routerLinkActive="active">
          固定項目マスタ
        </button> -->
        <button class="btn btn-block btn-outline-info m-1" routerLink="/master/additionalitemmaster"
            routerLinkActive="active">
            項目追加マスタ
        </button>
        <!-- <button class="btn btn-block btn-outline-info m-1" routerLink="/master/colormaster" routerLinkActive="active">
          カラーマスタ
        </button>
        <button class="btn btn-block btn-outline-info m-1" routerLink="/master/logomaster" routerLinkActive="active">
          ロゴマスタ
        </button> -->
        <button class="btn btn-block btn-outline-info m-1" routerLink="/master/sentencemaster"
            routerLinkActive="active">
            文言マスタ
        </button>
        <button class="btn btn-block btn-outline-info m-1" routerLink="/master/listuser" routerLinkActive="active">
            Cognito Users
        </button>
        <!--<button class="btn btn-block btn-outline-info m-1" routerLink="/master/initialcost" routerLinkActive="active">
          物件API（仮でDBから取得）
        </button>-->
    </div>
    <div class="col p-2">
        <router-outlet></router-outlet>
    </div>
</div>