import { Component } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { InitilaCostModalComponent } from '../estimate/initialCostModal.component';
import { EstimateRepository } from "../models/estimate.repository";
import { InitialCost } from '../models/InitialCost.model';
import { InitilaCostS3ModalComponent } from "./awss3/initialCostS3Modal.component";
import * as jsonData from '../../assets/data.json';

@Component({
    selector: "estimate-initial-cost-list",
    templateUrl: "initialCostList.component.html"
})
export class InitialCostListComponent {
    data: any = jsonData;

    constructor(
        private repo: EstimateRepository,
        private router: Router,
        public dialog: MatDialog) {
        this.repo.getInitialCostsFromMaster();
    }

    get initialCosts(): InitialCost[] {
        return this.repo.initialCosts;
    }

    get retry_count() {
        return this.data.default.retry_count;
    }

    openDialog(initialCostId: string, byAwsS3: boolean = false) {
        if (byAwsS3) {
            const ref: MatDialogRef<InitilaCostS3ModalComponent> = this.dialog.
                open(InitilaCostS3ModalComponent, {
                    data: initialCostId,
                    width: "95%",
                    maxHeight: "60%",
                    position: { top: '5%' },
                    hasBackdrop: true,
                    panelClass: ['animate__animated', 'animate__slideInLeft']
                });
        } else {
            const ref: MatDialogRef<InitilaCostModalComponent> = this.dialog.
                open(InitilaCostModalComponent, {
                    data: { id: initialCostId, awss3: false },
                    width: "95%",
                    maxHeight: "60%",
                    position: { top: '5%' },
                    hasBackdrop: true,
                    panelClass: ['animate__animated', 'animate__slideInLeft']
                });
        }
    }
}
