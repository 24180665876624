import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorDialogService } from "../../shared/errors/error-dialog.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Auth } from "aws-amplify";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorDialogService: ErrorDialogService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          Auth.signOut()
            .then(data => {
              console.log(data);
            })
            .catch(err => console.log(err))
          return EMPTY;
        }
        console.error("Error from error interceptor", error);
        let message = error.error ?? error.message ?? JSON.stringify(error);
        if (error.status == 0) {
          message = "ネットワーク接続エラーです。";
        }
        this.errorDialogService.openDialog(message, error.status);
        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}
