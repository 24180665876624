import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EstimateRepository } from "../models/estimate.repository";
import { AdditionalItem } from '../models/additionalItem.model';


@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'additionalItemsModal.component.html',
})
export class AdditionalItemsModalComponent {
  additionalItem: AdditionalItem;
  constructor(
    private repo: EstimateRepository,
    public dialogRef: MatDialogRef<AdditionalItemsModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: AdditionalItem) {
    this.additionalItem = new AdditionalItem(
      data.additionalItemId,
      data.customerId,
      data.name,
      data.cost,
      data.remarks,
      data.initialCostId,
      data.guid,
      data.order,
      data.canDelete);
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const details = this.repo.currentInitialCost.additionalItems;
    let matched = details.find(p => p.guid == this.additionalItem.guid);
    if (matched) {
      matched.name = this.additionalItem.name;
      matched.cost = this.additionalItem.cost;
      matched.remarks = this.additionalItem.remarks;
      this.repo.updateAdditionalItem(matched);
    } else {
      this.additionalItem.order = details.length + 1;
      this.repo.currentInitialCost.additionalItems.push(this.additionalItem);
      this.repo.createAdditionalItem(this.additionalItem);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
