import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EstimateRepository } from "../models/estimate.repository";
import { SentenceItem } from '../models/sentenceItem.model';

@Component({
  selector: 'estimate-initialC-cost-detail-modal',
  templateUrl: 'sentenceItemsModal.component.html',
})
export class SentenceItemsModalComponent {
  sentenceItem: SentenceItem;
  constructor(
    private repo: EstimateRepository,
    public dialogRef: MatDialogRef<SentenceItemsModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: SentenceItem) {
    this.sentenceItem = new SentenceItem(
      data.sentenceItemId,
      data.customerId,
      data.position,
      data.sentence,
      data.isSelected,
      data.order,
      data.initialCostId,
      data.guid);
  }

  public onOkClick(): void {
    this.dialogRef.close();

    const details = this.repo.currentInitialCost.sentenceItems;
    let matched = details.find(p => p.guid == this.sentenceItem.guid);
    if (matched) {
      matched.sentence = this.sentenceItem.sentence;
      this.repo.updateSentenceItem(matched);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
