import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../environments/environment";
import { AdminCreateUser } from "./adminCreateUser.model";
import { AdminUpdateUserAttributes } from "./adminUpdateUserAttributes.model";
import { UserType } from "./userType.model";

const baseUrl = environment.baseServerUrl;
const cognitoUrl = baseUrl + "/api/cognito";

@Injectable()
export class CognitoRepository {

    constructor(
        private http: HttpClient
    ) { }

    getListUser(email?: string, accountId?: string, insuranceCompanyId?: string, roleType?: string): Observable<UserType[]> {
        return this.http.get<UserType[]>(`${cognitoUrl}?email=${email}&accountId=${accountId}&insuranceCompanyId=${insuranceCompanyId}&roleType=${roleType}`);
    }

    getUser(userName: string): Observable<UserType> {
        return this.http.get<UserType>(`${cognitoUrl}/${userName}`);
    }

    insertUser(adminCreateUserData: AdminCreateUser): Observable<any> {
        return this.http.post(`${cognitoUrl}`, adminCreateUserData);
    }

    replaceUser(adminUpdateUserAttributes: AdminUpdateUserAttributes): Observable<any> {
        return this.http.put(`${cognitoUrl}`, adminUpdateUserAttributes);
    }

    deleteUser(userNsme: string): Observable<any> {
        return this.http.delete(`${cognitoUrl}/${userNsme}`);
    }
}