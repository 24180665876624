import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AnonymousRequestHttpClient } from "./anonymous-request-http-client";


@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    AnonymousRequestHttpClient
  ]
})
export class AnonymousRequestModule { }
