<h4 mat-dialog-title class="bg-info text-white p-2">
  文言マスタの{{sentenceMaster.sentenceMasterId == 0 ? "追加" : "編集" }}
</h4>
<div mat-dialog-content>
  <div *ngIf="detailsForm.invalid" class="validation-summary">
    <ul>
      <li *ngIf="sentence.invalid" class="text-danger">
        文言を入力してください
      </li>
    </ul>
  </div>
  <form novalidate #detailsForm="ngForm">
    <div class="form-group">
      <label>文言</label>
      <textarea #sentence="ngModel" name="sentence" class="form-control"
                [(ngModel)]="sentenceMaster.sentence" required rows="5"></textarea>
      <div *ngIf="sentence.invalid" class="text-danger">
        文言を入力してください
      </div>
    </div>
    <div class="form-group">
      <label>表示場所</label>
      <select #position="ngModel" name="position" class="form-control"
              [(ngModel)]="sentenceMaster.position"
              [compareWith]="comparePositions">
        <option *ngFor="let s of positions" [ngValue]="s.value">{{s.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>表示／非表示</label>
      <div class="custom-control custom-switch">
        <input #visible="ngModel" id="visible" name="visible" type="checkbox" class="custom-control-input"
               [(ngModel)]="sentenceMaster.visible" />
        <label class="custom-control-label" for="visible">
          表示
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>作成日時</label>
      {{ sentenceMaster.createdDate | date: 'medium'}}
    </div>
    <div class="form-group">
      <label>更新日時</label>
      {{ sentenceMaster.updatedDate | date: 'medium'}}
    </div>
    <div class="text-center pt-2">
      <button type="button" class="btn btn-outline-danger m-1"
              [disabled]="detailsForm.invalid"
              (click)="onOkClick()">
        登録
      </button>
      <button type="button" class="btn btn-outline-danger m-1"
              (click)="onNoClick()">
        戻る
      </button>
    </div>
  </form>
</div>
