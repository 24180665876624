
<div *ngIf="!initialCost?.staffName || !initialCost?.customerName" class="validation-summary">
  <ul>
    <li *ngIf="!initialCost?.staffName" class="text-danger">
      担当者名を入力してください
    </li>
    <li *ngIf="!initialCost?.customerName" class="text-danger">
      顧客名を入力してください
    </li>
  </ul>
</div>
<table *ngIf="initialCost" class="table table-bordered table">
  <!--<tr>
    <td class="nowrap">ロゴ</td>
    <td>
      <mat-select [(value)]="initialCost.logoMasterId"
                  (selectionChange)="updateInitialCostProperty()">
        <mat-option [ngValue]="null"></mat-option>
        <mat-option *ngFor="let m of logoMasters" [value]="m.logoMasterId">
          <img src='{{getImageSrc(m)}}' class="dropdown-logoImage" loading="lazy">{{fileName(m)}}
        </mat-option>
      </mat-select>
      <img class="logoImage" src="{{logoMaster(initialCost.logoMasterId) ? getImageSrc(logoMaster(initialCost.logoMasterId)) : null }}" />
    </td>
  </tr>
  <tr>
    <td class="nowrap">カラー</td>
    <td>
      <select class="form-control" [(ngModel)]="initialCost.colorMasterId"
              (change)="updateInitialCostProperty()"
              [ngStyle]="{'background-color': colorMaster(initialCost.colorMasterId) ? '#' + colorMaster(initialCost.colorMasterId).color.toHex() : null}">
        <option [ngValue]="null"></option>
        <option *ngFor="let m of colorMasters"
                [ngValue]="m.colorMasterId"
                [ngStyle]="{'background-color': '#' + m.color.toHex()}">
          #{{m.color.toHex()}}
        </option>
      </select>
    </td>
  </tr>-->
  <tr>
    <td class="nowrap">担当者名</td>
    <td>
      <input #staffName="ngModel" class="form-control" placeholder="担当者名" [(ngModel)]="initialCost.staffName" (change)="updateInitialCostProperty()" required [readonly]="readonly" />
      <div *ngIf="staffName.invalid" class="text-danger">
        担当者名
        を入力してください
      </div>
    </td>
  </tr>
  <tr>
    <td class="nowrap">担当者電話番号</td>
    <td><input class="form-control" placeholder="担当者電話番号" [(ngModel)]="initialCost.staffPhoneNumber" (change)="updateInitialCostProperty()" [readonly]="readonly" /></td>
  </tr>
  <tr>
    <td class="nowrap">顧客名</td>
    <td>
      <div class="input-group">
        <input #customerName="ngModel" class="form-control" placeholder="顧客名" [(ngModel)]="initialCost.customerName" (change)="updateInitialCostProperty()" required [readonly]="readonly" />
        <select [(ngModel)]="initialCost.honorificTitle" (change)="updateInitialCostProperty()" [disabled]="readonly">
          <option [ngValue]="null"></option>
          <option value="様">様</option>
          <option value="殿">殿</option>
        </select>
        <br />
      </div>
      <div *ngIf="customerName.invalid" class="text-danger">
        顧客名を入力してください
      </div>
    </td>
  </tr>
  <tr>
    <td class="nowrap">物件情報</td>
    <td>
      <input class="form-control m-2" placeholder="建物名" [(ngModel)]="initialCost.propertyName" (change)="updateInitialCostProperty()" [readonly]="readonly" />
      <input class="form-control m-2" placeholder="部屋番号" [(ngModel)]="initialCost.propertyRoomNumer" (change)="updateInitialCostProperty()" [readonly]="readonly" />
      <div class="input-group">
        <input class="form-control m-2 input-expand-panel" placeholder="間取り" [(ngModel)]="initialCost.propertyFloorPlan" (change)="updateInitialCostProperty()" [readonly]="readonly" />
        <button class="menu-button m-2" (click)="panelOpenState=!panelOpenState">詳細</button>
      </div>
      <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState" hideToggle>
          <input class="form-control m-2" placeholder="所在地" [(ngModel)]="initialCost.propertyInformation" (change)="updateInitialCostProperty()" [readonly]="readonly" />
        </mat-expansion-panel>
      </mat-accordion>
    </td>
  </tr>
</table>

