import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class ExternalAuthInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.getTokens().pipe(
            mergeMap((token) => {

                if (token) {
                    const idToken = token[0];
                    const accessToken = token[1];
                    const refreshToken = token[2];
                    console.log(`idToken: Bearer ${idToken}`);
                    console.log(`accessToken: ${accessToken}`);
                    console.log(`refreshToken: ${refreshToken}`);
                    const clonedRequest = request.clone({ headers: request.headers.append('Authorization', `Bearer ${idToken}`).append('Content-Type', 'application/json').append('AccessToken', `${accessToken}`).append("RefreshToken", `${refreshToken}`) });
                    // Pass the cloned request instead of the original request to the next handle
                    return next.handle(clonedRequest);
                } else {
                    //this.authenticationService.logout();
                    return EMPTY;
                }
            }),
            catchError(error => {
                console.log(error);
                return EMPTY;
            })
        );
    }

    getTokens() {
        return from(
            new Promise((resolve, reject) => {
                Auth.currentSession().then((session) => {
                    if (!session.isValid()) {
                        resolve(null);
                    } else {
                        resolve([session.getIdToken().getJwtToken(), session.getAccessToken().getJwtToken(), session.getRefreshToken().getToken()]);
                    }
                })
                    .catch(err => { return resolve(null) });
            })
        );
    }
}
