export class LogoMaster {
  constructor(
    public logoMasterId?: number,
    public logo?: string,
    public visible?: boolean,
    public order?: number,
    public createdDate?: Date,
    public updatedDate?: Date,
    public xmin?: BigInt,
    public createdBy?: string,
    public updatedBy?: string
  ) { }
}
